import React from "react";

import { makeTransactionText } from "utils/wallet";

import { WALLET_OPERATION_TYPE } from "constants/wallet.constants";

export const getTableColumns = (additionalProps) => {
    const { formatAmount, hasLiteMode } = additionalProps;

    return [
        {
            title: "backoffice.reports.transactionGroupId",
            dataIndex: "groupId",
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.reports.date",
            dataIndex: "transactionTime",
            isDateTime: true,
            sorter: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.reports.amount",
            dataIndex: "transactionAmount",
            mobileLevel: 3,
            render: (value, record) => (
                    <span
                        className={
                            (
                                [
                                    WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_FROM_BETSHOP,
                                    WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_BALANCE,
                                    WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_DEBT,
                                    WALLET_OPERATION_TYPE.AGENT_WITHDRAW_FROM_CREDIT_LINE,
                                    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE,
                                    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT,
                                    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_CREDIT,
                                    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_CREDIT_PAY_DEBT,
                                    WALLET_OPERATION_TYPE.AGENT_BETSHOP_WITHDRAWAL,
                                    WALLET_OPERATION_TYPE.SUPER_AGENT_WITHDRAWAL_BETSHOP,
                                    WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_WITHDRAW_AGENT,
                                    WALLET_OPERATION_TYPE.BETSHOP_MANAGER_WITHDRAWAL_CASHIER,
                                ].includes(record.transactionType)
                            )
                                ? 'rt--table-withdrawal'
                                : ''
                        }
                    >
                        {formatAmount(Math.abs(value), record.currencyCode, record.transactionTime)}
                    </span>
                )
        },
        ...(
            !hasLiteMode
                ? [{
                    title: "backoffice.reports.currency",
                    dataIndex: "currencyCode",
                    mobileLevel: 4,
                }]
                : []
        ),
        {
            title: "backoffice.reports.transactionType",
            dataIndex: "transactionType",
            mobileLevel: 5,
            render: (value) => makeTransactionText(value),
            tooltip: (value) => makeTransactionText(value),
        },
        {
            title: "backoffice.reports.participants",
            dataIndex: "participants",
            multi: true,
            disableExport: true,
            alwaysVisible: true,
            mobileLevel: 6,
        },
    ];
};
