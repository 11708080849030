import { combineReducers } from 'redux';

import AuthReducer from './auth/auth.reducer';
import AccountReducer from './dashboard/account.reducer';
import CommonReducer from './dashboard/common.reducer';
import CompaniesReducer from './dashboard/companies.reducer';
import ProjectsReducer from './dashboard/projects.reducer';
import AgentsReducer from './dashboard/agents.reducer';
import WalletReducer from './dashboard/wallet.reducer';
import AgentTransactionsReportReducer from './dashboard/agentTransactionsReport.reducer';
import CashierTransactionsReportReducer from './dashboard/cashierTransactionsReport.reducer';
import BetshopTransactionsReportReducer from './dashboard/betshopTransactionsReport.reducer';
import CommissionCalculationsReportReducer from './dashboard/commissionCalculationsReport.reducer';
import AgentPerformanceReportReducer from "./dashboard/agentPerformanceReport.reducer";
import PlayerPerformanceReportReducer from "./dashboard/playerPerformanceReport.reducer";
import BetshopPerformanceReportReducer from "./dashboard/betshopPerformanceReport.reducer";
import CashierPerformanceReportReducer from "./dashboard/cashierPerformanceReport.reducer";
import ProductReportReportReducer from "./dashboard/productReport.reducer";
import AgentCalculationHistoryReducer from './dashboard/agentCalculationHistory.reducer';
import CommissionPlansReducer from './dashboard/commissionPlans.reducer';
import NetworkReducer from "./dashboard/network.reducer";
import BethistoryReducer from './dashboard/betHistory.reducer';
import PlayersReducer from './dashboard/players.reducer';
import BetshopsReducer from './dashboard/betshops.reducer';
import CashiersReducer from './dashboard/cashiers.reducer';
import BetshopManagersReducer from './dashboard/betshopManagers.reducer';
import TerminalsReducer from './dashboard/terminals.reducer';
import UsersReducer from './dashboard/users.reducer';
import AccessManagersReducer from './dashboard/accessManagers.reducer';
import PermissionsReducer from './dashboard/permissions.reducer';
import PermissionGroupsReducer from './dashboard/permissionGroups.reducer';
import PermissionRequestsReducer from './dashboard/permissionRequests.reducer';
import ProfileReducer from './dashboard/profile.reducer';
import SessionsReducer from './dashboard/sessions.reducer';
import SystemCurrenciesReducer from './dashboard/systemCurrencies.reducer';
import TranslationsReducer from './dashboard/translations.reducer';
import PayoutTicketReducer from './dashboard/payoutTicket.reducer';
import SystemLanguagesReducer from './dashboard/systemLanguages.reducer';
import NotificationsReducer from './dashboard/notifications.reducer';
import FailedTransactionsReducer from './dashboard/failedTransactions.reducer';
import UserLogsReducer from './dashboard/userLogs.reducer';
import ErrorsReducer from './dashboard/errors.reducer';
import RequestsReducer from './dashboard/requests.reducer';
import GenerationsReducer from './dashboard/generations.reducer';
import DBConnectionsReducer from './dashboard/dbConnections.reducer';
import JobsReducer from './dashboard/jobs.reducer';
import MonitoringReducer from './dashboard/monitoring.reducer';
import CalculateReportsReducer from './dashboard/calculateReports.reducer';
import PostDeploymentActionsReducer from './dashboard/postDeploymentActions.reducer';
import PlatformsReducer from './dashboard/platforms.reducer';
import PaymentHistoryReducer from './dashboard/paymentHistory.reducer';
import PaymentRequestsReducer from './dashboard/paymentRequests.reducer';
import VoucherReducer from './dashboard/voucher.reducer';
import SystemReducer from './system/system.reducer';
import TerminalCustomizeReducer from './dashboard/terminalCustomize.reducer';
import VoucherHistoryReportReducer from "./dashboard/voucherHistoryReport.reducer";

export default () => combineReducers({
    auth: AuthReducer,
    account: AccountReducer,
    common: CommonReducer,
    companies: CompaniesReducer,
    projects: ProjectsReducer,
    agents: AgentsReducer,
    wallet: WalletReducer,
    commissionPlans: CommissionPlansReducer,
    network: NetworkReducer,
    agentTransactionsReport: AgentTransactionsReportReducer,
    cashierTransactionsReport: CashierTransactionsReportReducer,
    betshopTransactionsReport: BetshopTransactionsReportReducer,
    commissionCalculationsReport: CommissionCalculationsReportReducer,
    agentPerformanceReport: AgentPerformanceReportReducer,
    playerPerformanceReport: PlayerPerformanceReportReducer,
    betshopPerformanceReport: BetshopPerformanceReportReducer,
    cashierPerformanceReport: CashierPerformanceReportReducer,
    productReport: ProductReportReportReducer,
    agentCalculationHistory: AgentCalculationHistoryReducer,
    players: PlayersReducer,
    betHistory: BethistoryReducer,
    betshops: BetshopsReducer,
    cashiers: CashiersReducer,
    betshopManagers: BetshopManagersReducer,
    terminals: TerminalsReducer,
    users: UsersReducer,
    accessManagers: AccessManagersReducer,
    permissions: PermissionsReducer,
    permissionGroups: PermissionGroupsReducer,
    permissionRequests: PermissionRequestsReducer,
    profile: ProfileReducer,
    sessions: SessionsReducer,
    translations: TranslationsReducer,
    payoutTicket: PayoutTicketReducer,
    systemCurrencies: SystemCurrenciesReducer,
    systemLanguages: SystemLanguagesReducer,
    platforms: PlatformsReducer,
    notifications: NotificationsReducer,
    userLogs: UserLogsReducer,
    failedTransactions: FailedTransactionsReducer,
    errors: ErrorsReducer,
    requests: RequestsReducer,
    generations: GenerationsReducer,
    dbConnections: DBConnectionsReducer,
    jobs: JobsReducer,
    monitoring: MonitoringReducer,
    calculateReports: CalculateReportsReducer,
    postDeploymentActions: PostDeploymentActionsReducer,
    system: SystemReducer,
    paymentHistory: PaymentHistoryReducer,
    paymentRequests: PaymentRequestsReducer,
    terminalCustomize: TerminalCustomizeReducer,
    voucher: VoucherReducer,
    voucherHistoryReport: VoucherHistoryReportReducer
});
