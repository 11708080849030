import Status from "components/common/status";
import {STATUS_TYPES} from "constants/status.constants";

const getTableColumns = ({ t, dateService, formatAmount }) => [
    {
        title: t('backoffice.reports.voucherId'),
        dataIndex: 'id',
        alwaysVisible: true,
        mobileLevel: 1,
    },
    {
        title: t('backoffice.reports.createdBy'),
        dataIndex: 'createdByName',
        mobileLevel: 2,
    },
    {
        title: t('backoffice.reports.createdAt'),
        dataIndex: 'createdAt',
        render: value => dateService.format(value, true, true),
        mobileLevel: 3,
    },
    {
        title: t('backoffice.reports.amount'),
        dataIndex: 'amount',
        render: (value, record) => formatAmount(value, record.currencyCode),
        mobileLevel: 5,
    },
    {
        title: t('backoffice.reports.currency'),
        dataIndex: 'currencyCode'
    },
    {
        title: t('backoffice.reports.executedBy'),
        dataIndex: 'usedByName',
        render: value => value || "-"
    },
    {
        title: t('backoffice.reports.betShopName'),
        dataIndex: 'betShopName',
        mobileLevel: 6,
    },
    {
        title: t('backoffice.reports.paidOutBy'),
        dataIndex: 'paidOutByName',
        render: value => value || "-"
    },
    {
        title: t('backoffice.reports.paidOutDate'),
        dataIndex: 'paidOutDate',
        render: value => dateService.format(value, true, true) || "-"
    },
    {
        title: t('backoffice.reports.status'),
        dataIndex: 'status',
        render: value => <Status status={value} type={STATUS_TYPES.VOUCHER} />,
        mobileLevel: 4,
    },
    {
        title: t('backoffice.reports.expirationDate'),
        dataIndex: 'expirationDate',
        render: value => dateService.format(value, true, true) || "-"
    }
]

export default getTableColumns;
