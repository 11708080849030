import React from "react";

import VoucherHistoryReport from "pages/reports/voucherHistoryReport";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const VoucherHistoryReportRoute = () => {
    return <VoucherHistoryReport />;
};

export default withPermission(
    withAuth(VoucherHistoryReportRoute),
    { resource: PERMISSION_RESOURCE.REPORTS_VOUCHER_HISTORY, action: PERMISSION_ACTION.VIEW },
    Paths.DEFAULT
);
