//#region react
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getTerminals,
    setTerminalsSorting,
    setTerminalsFilters
} from "store/actions/dashboard/retail/terminals/terminals.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import TerminalCreateComponent from "./terminal-create.component";
import Filters from "./filters.component";
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { hasPermission, hasOneOfPermissions } from 'utils/permissions';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";

import Paths from 'constants/path.constants';
import ApiUrls from 'constants/api.constants';
import { TERMINALS } from "constants/pageName.constants";
import { getTableColumns } from "./columns";
//#endregion

//#region types
import terminalType from "types/terminal/terminal.type";
import sortingType from "types/common/sorting.type";
//#endregion

/** Terminals Page Component */
const TerminalsComponent = ({
    getTerminals,
    terminals,
    isLoading,
    total,
    setTerminalsSorting,
    setTerminalsFilters,
    sorting,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(false);

    const { formatAmount } = useFormat();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: TERMINALS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.TERMINAL,
        action: PERMISSION_ACTION.EXPORT
    })

    const hasTerminalCreatePermission = hasPermission({
        resource: PERMISSION_RESOURCE.TERMINAL,
        action: PERMISSION_ACTION.CREATE
    })

    const canNavigateTerminalEditPage = hasOneOfPermissions([
        { resource: PERMISSION_RESOURCE.TERMINAL_GENERALINFO, action: PERMISSION_ACTION.VIEW }
    ])

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (value, item) => {
        setTerminalsFilters({
            ...filters,
            nameOrId: value ? item ? item.id : value : ""
        })
    }

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${Paths.TERMINALS_EDIT}/${record.id}` +
            `?name=${record.name}`
        )
    }

    const showCreatePopup = () => {
        setIsCreatePopupVisible(true)
    }

    const hideCreatePopup = () => {
        setIsCreatePopupVisible(false)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                formatAmount
            }
        });
    }, [includedColumns, formatAmount])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (canNavigateTerminalEditPage) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "icon-right",
            onClick: handleRightArrowIconClick
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            placeholder: t("backoffice.terminals.terminalNameOrId"),
            loadFn: getTerminals,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.TERMINAL,
                dependencies: [globalProjectId]
            },
            onSearch: handleSearchChange,
        },
        buttons: [{
            text: t("backoffice.terminals.createTerminal"),
            icon: "icon-plus",
            type: "primary",
            enabled: hasTerminalCreatePermission,
            onClick: showCreatePopup,
        }],
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.terminals') }]
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.terminals"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_TERMINALS,
            filters: filters,
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={terminals}
                loadFn={getTerminals}
                sorting={sorting}
                setSortingFn={setTerminalsSorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
            />

            {isCreatePopupVisible && <TerminalCreateComponent onClose={hideCreatePopup} />}

        </MainDashboardLayout>
    )
};

/** TerminalsComponent propTypes
 * PropTypes
*/
TerminalsComponent.propTypes = {
    /** Redux action to get terminals */
    getTerminals: PropTypes.func,
    /** Redux state property, represents the array of terminals  */
    terminals: PropTypes.arrayOf(terminalType),
    /** Redux state property, is true when loading terminals */
    isLoading: PropTypes.bool,
    /** Redux state property, terminals total count */
    total: PropTypes.number,
    /** Redux action to set terminals sorting details */
    setTerminalsSorting: PropTypes.func,
    /** Redux action to set terminals sorting details */
    setTerminalsFilters: PropTypes.func,
    /** Redux state property, terminals sorting details */
    sorting: sortingType,
    /** Redux state property, terminals filters */
    filters: PropTypes.object,
    /** Redux state property, represents global company id */
    globalProjectId: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getTerminals: nextPage => {
        dispatch(getTerminals(nextPage));
    },
    setTerminalsSorting: sorting => {
        dispatch(setTerminalsSorting(sorting));
    },
    setTerminalsFilters: filters => {
        dispatch(setTerminalsFilters(filters));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.terminals.isLoading,
        terminals: state.terminals.terminals,
        total: state.terminals.total,
        sorting: state.terminals.sorting,
        filters: state.terminals.filters,
        globalProjectId: state.common.globalProjectId,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TerminalsComponent);
