import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from '../edit/sections/general';
import CurrenciesComponent from '../edit/sections/currencies';
import AgentSystemComponent from '../edit/sections/agentSystem';
import WalletsComponent from '../edit/sections/wallets';
import FavAmountsAndLimitsComponent from '../edit/sections/favAmountsAndLimits';
import RegistrationFormComponent from '../edit/sections/registrationForm';
import IntegrationComponent from '../edit/sections/integration';
import ConfigsComponent from '../edit/sections/configs';
import ProjectPaymentsComponent from '../edit/sections/payments';
import TerminalComponent from './sections/terminal';

import { binaryToFlags, isFranchisingMode } from 'utils/common';
import { hasPermission } from "utils/permissions";

import Paths from 'constants/path.constants';
import { PROJECT_PROVIDER_TYPE } from 'constants/project.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import useProjectType from "hooks/useProjectType";
import useCurrentProject from 'hooks/useCurrentProject';

/** Project Edit Page Component */
const ProjectEditComponent = () => {
    const { t } = useTranslation();

    const { hasLiteMode, hasStandartPayment, hasAgentSystem, hasTerminal, hasRetail } = useProjectType();

    const currentProject = useCurrentProject();
    const provider = currentProject?.availableProviders;

    const showLimits = useMemo(() => {
        const hasLimitPermission = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_LIMITS, action: PERMISSION_ACTION.VIEW });
        const hasVSLimitsPermission = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_VS_BET_LIMITS, action: PERMISSION_ACTION.VIEW });

        const availableProviders = binaryToFlags(Object.values(PROJECT_PROVIDER_TYPE), provider);
        const virtualSportEnabled = availableProviders.includes(PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS);

        return hasLimitPermission || (virtualSportEnabled && hasVSLimitsPermission);
    }, [provider]);


    const items = [
        {
            title: t("backoffice.projects.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_GENERALINFO, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent />
        },
        {
            title: t("backoffice.projects.currencies"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_CURRENCIES, action: PERMISSION_ACTION.VIEW }],
            component: <CurrenciesComponent />,
            disabled: hasLiteMode
        },
        {
            title: isFranchisingMode() ? t("backoffice.projects.franchising") : t("backoffice.projects.agentSystem"),
            permissions: [
                { resource: PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, action: PERMISSION_ACTION.VIEW }
            ].concat(!isFranchisingMode() ?[
                { resource: PERMISSION_RESOURCE.PROJECT_AGENT_SYSTEM, action: PERMISSION_ACTION.VIEW }
            ] : []),
            component: <AgentSystemComponent />,
            disabled: !hasAgentSystem,
            pathParams: { name: "commissionPlanName", id: "commissionPlanId" },
            destroyIfInactive: true
        },
        {
            title: t("backoffice.projects.terminal"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_TERMINALS, action: PERMISSION_ACTION.VIEW }],
            disabled: !hasTerminal,
            component: <TerminalComponent />
        },
        {
            title: t("backoffice.projects.wallets"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_WALLETS, action: PERMISSION_ACTION.VIEW }],
            component: <WalletsComponent />
        },
        {
            title: hasStandartPayment ? t("backoffice.projects.favAmountsAndLimits") : t("backoffice.projects.limits"),
            disabled: !showLimits,
            component: <FavAmountsAndLimitsComponent />
        },
        {
            title: t("backoffice.projects.registrationForm"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_FORM, action: PERMISSION_ACTION.VIEW }],
            component: <RegistrationFormComponent />
        },

        {
            title: t("backoffice.projects.configs"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_REGULATIONS, action: PERMISSION_ACTION.VIEW }],
            component: <ConfigsComponent />,
            disabled: !hasRetail,
        },

        {
            title: t("backoffice.projects.integration"),
            permissions: [
                { resource: PERMISSION_RESOURCE.PROJECT_INTEGRATION, action: PERMISSION_ACTION.VIEW }
            ].concat( hasRetail ? [
                { resource: PERMISSION_RESOURCE.PROJECT_PROVIDER_SETTINGS, action: PERMISSION_ACTION.VIEW }
            ] : []),
            component: <IntegrationComponent />
        },
        {
            title: t("backoffice.projects.payments"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_PAYMENT, action: PERMISSION_ACTION.VIEW }],
            component: <ProjectPaymentsComponent />,
            disabled: isFranchisingMode() || hasLiteMode,
            pathParams: { name: "bankName", id: "bankId" }
        },
    ]

    return (
        <Tabs
            items={items}
            mainPage={
                {
                    title: t('backoffice.menu.projects'),
                    path: Paths.PROJECTS
                }
            }
        />
    )
}

export default ProjectEditComponent;
