import Paths from 'constants/path.constants';

import { isFranchisingMode } from 'utils/common';

import { PROJECT_TYPE } from "constants/project.constants";
import { USER_TYPE, USER_ROLE } from "constants/user.constants";

import { PERMISSION_RESOURCE, PERMISSION_ACTION} from 'constants/permissions.constants';
import { PAYMENT_METHODS } from 'constants/project.constants';

import { getUser } from 'utils/auth';
import {PROJECT_MODE} from "constants/common.constants";
import NewTag from "components/common/newTag";

export default () => ([
    {
        id: '0',
        icon: 'icon-dashboard',
        title: 'backoffice.menu.dashboard',
        url: Paths.DASHBOARD,
        match: [Paths.DASHBOARD],
        permission: { resource: PERMISSION_RESOURCE.DASHBOARD, action: PERMISSION_ACTION.VIEW },
        projectType: [PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM],
        paymentType: [ PAYMENT_METHODS.STANDART ],
        isProjectRequired: true,
    },
    {
        id: '1',
        icon: 'icon-companies',
        title: 'backoffice.menu.companies',
        url: Paths.COMPANIES,
        permission: { resource: PERMISSION_RESOURCE.COMPANY, action: PERMISSION_ACTION.VIEW },
        match: [Paths.COMPANIES, Paths.COMPANIES_EDIT + "/:id"],
        projectType: [PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM],
        userRole: [USER_ROLE.ADMIN]
    },
    {
        id: '2',
        icon: 'icon-globe',
        title: 'backoffice.menu.projects',
        url: Paths.PROJECTS,
        permission: { resource: PERMISSION_RESOURCE.PROJECT, action: PERMISSION_ACTION.VIEW },
        match: [Paths.PROJECTS, Paths.PROJECTS_EDIT + "/:id"],
        projectType: [PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM],
        userRole: [USER_ROLE.ADMIN ]
    },
    {
        id: '3',
        icon: 'icon-agent',
        title: isFranchisingMode() ? 'backoffice.menu.franchising' : 'backoffice.menu.agentSystem',
        disabled: isFranchisingMode() && getUser()?.role === USER_ROLE.AGENT,
        permission: [
            { resource: PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.PERMISSION, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.AGENT_NETWORK, action: PERMISSION_ACTION.VIEW }
        ],
        items: [
            {
                id: '31',
                title: isFranchisingMode() ? 'backoffice.menu.betshopOwners' : 'backoffice.menu.agents',
                url: isFranchisingMode() ? Paths.BETSHOP_OWNERS : Paths.AGENTS,
                match: [Paths.AGENTS, Paths.AGENTS_EDIT + "/:id", Paths.BETSHOP_OWNERS, Paths.BETSHOP_OWNERS_EDIT + "/:id"],
                permission: { resource: PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.VIEW },
                disabled: isFranchisingMode() && getUser()?.role === USER_ROLE.AGENT,
                projectType: [PROJECT_TYPE.AGENT_SYSTEM],
                isProjectRequired: true
            },
            {
                id: '32',
                title: 'backoffice.menu.permissionGroups',
                url: Paths.PERMISSION_GROUPS + `?type=${USER_TYPE.AGENT}`,
                match: [Paths.PERMISSION_GROUPS + `?type=${USER_TYPE.AGENT}`, Paths.PERMISSION_GROUPS_EDIT + "/:id" + `?type=${USER_TYPE.AGENT}`],
                permission: { resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW },
                disabled: isFranchisingMode() && getUser()?.role === USER_ROLE.AGENT,
                projectType: [PROJECT_TYPE.AGENT_SYSTEM],
                projectMode: [PROJECT_MODE.LITE, PROJECT_MODE.STANDARD],
                isProjectRequired: true
            },
            {
                id: '33',
                title: 'backoffice.menu.permissions',
                url: Paths.PERMISSIONS + `?type=${USER_TYPE.AGENT}`,
                match: [Paths.PERMISSIONS + `?type=${USER_TYPE.AGENT}`],
                permission: { resource: PERMISSION_RESOURCE.PERMISSION, action: PERMISSION_ACTION.VIEW },
                disabled: isFranchisingMode() && getUser()?.role === USER_ROLE.AGENT,
                projectType: [PROJECT_TYPE.AGENT_SYSTEM],
                projectMode: [PROJECT_MODE.LITE, PROJECT_MODE.STANDARD],
                isProjectRequired: true
            },
            {
                id: '34',
                title: 'backoffice.menu.networkOverview',
                url: Paths.NETWORK ,
                match: [Paths.NETWORK],
                permission: { resource: PERMISSION_RESOURCE.AGENT_NETWORK, action: PERMISSION_ACTION.VIEW },
                disabled: isFranchisingMode() && getUser()?.role === USER_ROLE.AGENT,
                projectType: [PROJECT_TYPE.AGENT_SYSTEM],
                isProjectRequired: true
            }
        ],
        projectType: [PROJECT_TYPE.AGENT_SYSTEM],
        isProjectRequired: true
    },
    {
        id: '4',
        icon: 'icon-payments',
        title: 'backoffice.menu.payments',
        permission: [
            { resource: PERMISSION_RESOURCE.PAYMENT_DEPOSIT_TRANSACTION_HISTORY, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.PAYMENT_WITHDRAW_TRANSACTION_HISTORY, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.PAYMENT_DEPOSIT_TRANSACTION_REQUEST, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.PAYMENT_WITHDRAW_TRANSACTION_REQUEST, action: PERMISSION_ACTION.VIEW }
        ],
        projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
        paymentType: [ PAYMENT_METHODS.BANK_TRANSFER, PAYMENT_METHODS.EPOS ],
        items: [
            {
                id: '41',
                title: 'backoffice.menu.paymentRequests',
                url: Paths.PAYMENTS_REQUESTS,
                match: [Paths.PAYMENTS_REQUESTS],
                permission: [
                    { resource: PERMISSION_RESOURCE.PAYMENT_DEPOSIT_TRANSACTION_REQUEST, action: PERMISSION_ACTION.VIEW },
                    { resource: PERMISSION_RESOURCE.PAYMENT_WITHDRAW_TRANSACTION_REQUEST, action: PERMISSION_ACTION.VIEW }
                ],
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                paymentType: [ PAYMENT_METHODS.BANK_TRANSFER, PAYMENT_METHODS.EPOS ]
            },
            {
                id: '42',
                title: 'backoffice.menu.paymentHistory',
                url: Paths.PAYMENT_HISTORY,
                match: [Paths.PAYMENT_HISTORY],
                permission: [
                    { resource: PERMISSION_RESOURCE.PAYMENT_DEPOSIT_TRANSACTION_HISTORY, action: PERMISSION_ACTION.VIEW },
                    { resource: PERMISSION_RESOURCE.PAYMENT_WITHDRAW_TRANSACTION_HISTORY, action: PERMISSION_ACTION.VIEW }
                ],
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                paymentType: [ PAYMENT_METHODS.BANK_TRANSFER, PAYMENT_METHODS.EPOS ]
            }
        ]
    },
    {
        id: '5',
        icon: 'icon-report',
        title: 'backoffice.menu.reports',
        permission: [
            { resource: PERMISSION_RESOURCE.REPORTS_AGENT_TRANSACTIONS, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_TRANSACTIONS, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.REPORTS_CASHIER_TRANSACTIONS, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.REPORTS_COMMISSION_CALCULATIONS, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.REPORTS_AGENT_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.REPORTS_PLAYER_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.REPORTS_PRODUCTS_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.REPORTS_VOUCHER_HISTORY, action: PERMISSION_ACTION.VIEW },
        ],
        projectType: [PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM],
        isProjectRequired: true,
        items: [
            {
                id: '51',
                title: 'backoffice.menu.userTransactions' ,
                url: isFranchisingMode() ? Paths.REPORTS_BETSHOP_OWNER_TRANSACTIONS : Paths.REPORTS_AGENT_TRANSACTIONS,
                match: [isFranchisingMode() ? Paths.REPORTS_BETSHOP_OWNER_TRANSACTIONS : Paths.REPORTS_AGENT_TRANSACTIONS],
                permission: { resource: PERMISSION_RESOURCE.REPORTS_AGENT_TRANSACTIONS, action: PERMISSION_ACTION.VIEW },
                projectType: [PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM],
                isProjectRequired: true,
            },
            {
                id: '52',
                title: 'backoffice.menu.cashierTransactions',
                url: Paths.REPORTS_CASHIER_TRANSACTIONS,
                match: [Paths.REPORTS_CASHIER_TRANSACTIONS],
                permission: { resource: PERMISSION_RESOURCE.REPORTS_CASHIER_TRANSACTIONS, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.RETAIL_SYSTEM ],
                isProjectRequired: true,
            },
            {
                id: '53',
                title: 'backoffice.menu.betshopTransactions',
                url: Paths.REPORTS_BETSHOP_TRANSACTIONS,
                match: [Paths.REPORTS_BETSHOP_TRANSACTIONS],
                permission: { resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_TRANSACTIONS, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.RETAIL_SYSTEM ],
                isProjectRequired: true,
            },
            {
                id: '54',
                title: 'backoffice.menu.commissionCalculations',
                url: Paths.REPORTS_COMMISSION_CALCULATIONS,
                match: [Paths.REPORTS_COMMISSION_CALCULATIONS, Paths.REPORTS_AGENT_CALCULATION_HISTORY + "/:id"],
                permission: { resource: PERMISSION_RESOURCE.REPORTS_COMMISSION_CALCULATIONS, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM ],
                projectMode: [ PROJECT_MODE.STANDARD ],
                isProjectRequired: true,
            },
            {
                id: '55',
                title: 'backoffice.menu.performanceReport',
                url: Paths.REPORTS_PERFORMANCE_REPORTS,
                match: [Paths.REPORTS_PERFORMANCE_REPORTS, Paths.REPORTS_AGENT_PERFORMANCE_REPORT_EDIT + "/:id"],
                permission: [
                    { resource: PERMISSION_RESOURCE.REPORTS_AGENT_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
                    { resource: PERMISSION_RESOURCE.REPORTS_PLAYER_PERFORMANCE, action: PERMISSION_ACTION.VIEW }
                ],
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM ],
                isProjectRequired: true,
                disabled: isFranchisingMode(),
            },
            {
                id: '58',
                title: 'backoffice.menu.betshopPerformanceReport',
                url: Paths.REPORTS_BETSHOP_PERFORMANCE_REPORT,
                match: [Paths.REPORTS_BETSHOP_PERFORMANCE_REPORT, Paths.REPORTS_CASHIER_PERFORMANCE_REPORT + "/:id"],
                permission: { resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.RETAIL_SYSTEM ],
                isProjectRequired: true
            },
            {
                id: '59',
                title: 'backoffice.menu.productReport',
                url: Paths.REPORTS_PRODUCT_REPORT,
                match: [Paths.REPORTS_PRODUCT_REPORT],
                permission: { resource: PERMISSION_RESOURCE.REPORTS_PRODUCTS_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
                projectMode: [ PROJECT_MODE.LITE ],
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM ],
                isProjectRequired: true
            },
            {
                id: '510',
                title: 'backoffice.menu.newProductReport',
                renderTitle: title => <div className="rt--flex rt--justify-between rt--align-center">{title} <NewTag /></div>,
                url: Paths.NEW_REPORTS_PRODUCT_REPORT,
                match: [Paths.NEW_REPORTS_PRODUCT_REPORT],
                disabled: isFranchisingMode(),
                permission: [
                    { resource: PERMISSION_RESOURCE.REPORTS_PRODUCTS_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
                ],
                projectMode: [PROJECT_MODE.STANDARD ],
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM ],
                isProjectRequired: true
            },
            {
                id: '511',
                title: 'backoffice.menu.voucherHistory',
                url: Paths.REPORTS_VOUCHER_HISTORY_REPORT,
                match: [Paths.REPORTS_VOUCHER_HISTORY_REPORT],
                disabled: !isFranchisingMode(),
                permission: [
                    { resource: PERMISSION_RESOURCE.REPORTS_VOUCHER_HISTORY, action: PERMISSION_ACTION.VIEW },
                ],
                projectMode: [PROJECT_MODE.STANDARD ],
                projectType: [ PROJECT_TYPE.TERMINAL ],
                isProjectRequired: true
            }
        ]
    },
    {
        id: '6',
        icon: 'icon-player',
        title: 'backoffice.menu.players',
        permission: { resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.VIEW },
        url: Paths.PLAYERS,
        match: [Paths.PLAYERS, Paths.PLAYERS_EDIT + "/:id"],
        disabled: isFranchisingMode(),
        projectType: [ PROJECT_TYPE.AGENT_SYSTEM ],
        isProjectRequired: true,
    },
    {
        id: '7',
        icon: 'icon-betslip',
        title: 'backoffice.menu.betHistory',
        permission: [
            { resource: PERMISSION_RESOURCE.BETS_ONLINE, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.BETS_RETAIL, action: PERMISSION_ACTION.VIEW }
        ],
        projectType: [PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM],
        isProjectRequired: true,
        items: [
            {
                id: '71',
                title: 'backoffice.menu.realTimeBets',
                url: Paths.REAL_TIME_SPORT_BETS,
                match: [Paths.REAL_TIME_SPORT_BETS],
                permission: { resource: PERMISSION_RESOURCE.BETS_ONLINE, action: PERMISSION_ACTION.VIEW },
                projectType: [PROJECT_TYPE.AGENT_SYSTEM],
                isProjectRequired: true,
                disabled: isFranchisingMode()
            },
            {
                id: '72',
                title: 'backoffice.menu.online',
                url: Paths.ONLINE_BETHISTORY,
                match: [Paths.ONLINE_BETHISTORY],
                permission: { resource: PERMISSION_RESOURCE.BETS_ONLINE, action: PERMISSION_ACTION.VIEW },
                projectType: [PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM],
                isProjectRequired: true,
                disabled: isFranchisingMode()
            },
            {
                id: '73',
                title: 'backoffice.menu.cashier',
                url: Paths.RETAIL_BETHISTORY,
                match: [Paths.RETAIL_BETHISTORY],
                permission: { resource: PERMISSION_RESOURCE.BETS_RETAIL, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.RETAIL_SYSTEM ],
                isProjectRequired: true,
            },
            {
                id: '74',
                title: 'backoffice.menu.terminal',
                url: Paths.TERMINAL_BETHISTORY,
                match: [Paths.TERMINAL_BETHISTORY],
                permission: { resource: PERMISSION_RESOURCE.BETS_RETAIL, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.TERMINAL ],
                isProjectRequired: true,
            }
        ]
    },
    {
        id: '8',
        icon: 'icon-retail',
        title: 'backoffice.menu.retail',
        permission: [
            { resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.BETSHOP_MANAGER, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.TERMINAL, action: PERMISSION_ACTION.VIEW }
        ],
        projectType: [ PROJECT_TYPE.RETAIL_SYSTEM ],
        isProjectRequired: true,
        items: [
            {
                id: '81',
                title: 'backoffice.menu.betshops',
                url: Paths.BETSHOPS,
                match: [Paths.BETSHOPS, Paths.BETSHOPS_EDIT + "/:id"],
                permission: { resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.RETAIL_SYSTEM ],
                isProjectRequired: true,
            },
            {
                id: '82',
                title: 'backoffice.menu.cashiers',
                url: Paths.CASHIERS,
                match: [Paths.CASHIERS, Paths.CASHIERS_EDIT + "/:id"],
                permission: { resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.RETAIL_SYSTEM ],
                isProjectRequired: true,
            },
            {
                id: '83',
                title: 'backoffice.menu.betshopManagers',
                url: Paths.BETSHOP_MANAGERS,
                match: [Paths.BETSHOP_MANAGERS, Paths.BETSHOP_MANAGERS_EDIT + "/:id"],
                permission: { resource: PERMISSION_RESOURCE.BETSHOP_MANAGER, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.RETAIL_SYSTEM ],
                isProjectRequired: true,
            },
            {
                id: '84',
                title: 'backoffice.menu.terminals',
                url: Paths.TERMINALS,
                match: [Paths.TERMINALS, Paths.TERMINALS_EDIT + "/:id"],
                permission: { resource: PERMISSION_RESOURCE.TERMINAL, action: PERMISSION_ACTION.VIEW },
                isProjectRequired: true,
                projectType: [ PROJECT_TYPE.TERMINAL ],
                disabled: !isFranchisingMode()
            }
        ]
    },
    {
        id: '9',
        icon: 'icon-users',
        title: 'backoffice.menu.adminManagement',
        permission: [
            { resource: PERMISSION_RESOURCE.ADMIN, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.ACCESS_MANAGER, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.PERMISSION, action: PERMISSION_ACTION.VIEW }
        ],
        projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
        userRole: [USER_ROLE.ADMIN, USER_ROLE.ACCESS_MANAGER],
        items: [
            {
                id: '91',
                title: 'backoffice.menu.admins',
                url: Paths.USERS,
                permission:{ resource: PERMISSION_RESOURCE.ADMIN, action: PERMISSION_ACTION.VIEW },
                match: [Paths.USERS, Paths.USERS_EDIT + "/:id"],
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN, USER_ROLE.ACCESS_MANAGER]
            },
            {
                id: '92',
                title: 'backoffice.menu.accessManagers',
                url: Paths.ACCESS_MANAGERS,
                permission:{ resource: PERMISSION_RESOURCE.ACCESS_MANAGER, action: PERMISSION_ACTION.VIEW },
                match: [Paths.ACCESS_MANAGERS, Paths.ACCESS_MANAGERS_EDIT + "/:id"],
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN, USER_ROLE.ACCESS_MANAGER]
            },
            {
                id: '93',
                title: 'backoffice.menu.permissionGroups',
                url: Paths.PERMISSION_GROUPS + `?type=${USER_TYPE.ADMIN}`,
                match: [Paths.PERMISSION_GROUPS + `?type=${USER_TYPE.ADMIN}`, Paths.PERMISSION_GROUPS_EDIT + "/:id" + `?type=${USER_TYPE.ADMIN}`],
                permission: { resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN, USER_ROLE.ACCESS_MANAGER]
            },
            {
                id: '94',
                title: 'backoffice.menu.permissions',
                url: Paths.PERMISSIONS + `?type=${USER_TYPE.ADMIN}`,
                match: [Paths.PERMISSIONS + `?type=${USER_TYPE.ADMIN}`],
                permission: { resource: PERMISSION_RESOURCE.PERMISSION, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: '95',
                title: 'backoffice.menu.requests',
                url: Paths.PERMISSION_REQUESTS,
                match: [Paths.PERMISSION_REQUESTS],
                permission: { resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN, USER_ROLE.ACCESS_MANAGER]
            }
        ]
    },
    {
        id: 'a',
        icon: 'icon-failed-request',
        title: 'backoffice.menu.failedTransactions',
        url: Paths.FAILED_TRANSACTIONS,
        permission: { resource: PERMISSION_RESOURCE.FAILED_TRANSACTIONS, action: PERMISSION_ACTION.VIEW },
        match: [Paths.FAILED_TRANSACTIONS],
        projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
        userRole: [USER_ROLE.ADMIN],
        isProjectRequired: true
    },
    {
        id: 'b',
        icon: 'icon-userLogs',
        title: 'backoffice.menu.userLogs',
        url: Paths.USER_LOGS,
        permission: { resource: PERMISSION_RESOURCE.USER_LOGS, action: PERMISSION_ACTION.VIEW },
        match: [Paths.USER_LOGS],
        projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
        userRole: [USER_ROLE.ADMIN]
    },
    {
        id: 'c',
        icon: 'icon-cms',
        title: 'backoffice.menu.cms',
        permission: [
            { resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_TICKET, action: PERMISSION_ACTION.VIEW }
        ],
        userRole: [USER_ROLE.ADMIN],
        items: [
            {
                id: 'c1',
                title: 'backoffice.menu.retailTranslations',
                url: Paths.TRANSLATIONS_RETAIL,
                match: [Paths.TRANSLATIONS_RETAIL],
                permission: { resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'c2',
                title: 'backoffice.menu.boTranslations',
                url: Paths.TRANSLATIONS_BO,
                match: [Paths.TRANSLATIONS_BO],
                permission: { resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'c3',
                title: 'backoffice.menu.widgetTranslations',
                url: Paths.TRANSLATIONS_WIDGET,
                match: [Paths.TRANSLATIONS_WIDGET],
                permission: { resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'c4',
                title: 'backoffice.menu.agentTranslations',
                url: Paths.TRANSLATIONS_AGENT,
                match: [Paths.TRANSLATIONS_AGENT],
                permission: { resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'c5',
                title: 'backoffice.menu.terminalTranslations',
                url: Paths.TRANSLATIONS_TERMINAL,
                match: [Paths.TRANSLATIONS_TERMINAL],
                permission: { resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'c6',
                title: 'backoffice.menu.payoutTicket',
                url: Paths.PAYOUT_TICKET,
                match: [Paths.PAYOUT_TICKET],
                permission: { resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_TICKET, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.RETAIL_SYSTEM ],
                isProjectRequired: true,
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'c7',
                title: 'backoffice.menu.voucher',
                url: Paths.VOUCHER,
                match: [Paths.VOUCHER],
                permission: { resource: PERMISSION_RESOURCE.PROJECT_VOUCHER_TICKET, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.TERMINAL ],
                isProjectRequired: true,
                userRole: [USER_ROLE.ADMIN]
            }
        ]
    },
    {
        id: 'd',
        icon: 'icon-settings',
        title: 'backoffice.menu.settings',
        permission: [
            { resource: PERMISSION_RESOURCE.SYSTEM_CURRENCIES, action: PERMISSION_ACTION.VIEW },
            { resource: PERMISSION_RESOURCE.SYSTEM_LANGUAGES, action: PERMISSION_ACTION.VIEW }
        ],
        projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
        userRole: [USER_ROLE.ADMIN],
        items: [
            {
                id: 'd1',
                title: 'backoffice.menu.currencies',
                url: Paths.SETTINGS_CURRENCIES,
                match: [Paths.SETTINGS_CURRENCIES],
                permission: { resource: PERMISSION_RESOURCE.SYSTEM_CURRENCIES, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'd2',
                title: 'backoffice.menu.languages',
                url: Paths.SETTINGS_LANGUAGES,
                match: [Paths.SETTINGS_LANGUAGES],
                permission: { resource: PERMISSION_RESOURCE.SYSTEM_LANGUAGES, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            }
        ]
    },
    {
        id: 'e',
        icon: 'icon-code',
        title: 'backoffice.menu.developerSpace',
        permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
        projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
        userRole: [USER_ROLE.ADMIN],
        items: [
            {
                id: 'e1',
                title: 'backoffice.menu.errors',
                url: Paths.DEVELOPER_ERRORS,
                match: [Paths.DEVELOPER_ERRORS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'e2',
                title: 'backoffice.menu.integrationRequests',
                url: Paths.DEVELOPER_REQUESTS,
                match: [Paths.DEVELOPER_REQUESTS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'e3',
                title: 'backoffice.menu.jobs',
                url: Paths.DEVELOPER_JOBS,
                match: [Paths.DEVELOPER_JOBS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'e4',
                title: 'backoffice.menu.serviceMonitoring',
                url: Paths.DEVELOPER_MONITORING,
                match: [Paths.DEVELOPER_MONITORING],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'e5',
                title: 'backoffice.menu.platformTest',
                url: Paths.DEVELOPER_PLATFORM_TEST,
                match: [Paths.DEVELOPER_PLATFORM_TEST],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'e6',
                title: 'backoffice.menu.generations',
                url: Paths.DEVELOPER_GENERATIONS,
                match: [Paths.DEVELOPER_GENERATIONS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'e7',
                title: 'backoffice.menu.calculateReports',
                url: Paths.DEVELOPER_CALCULATE_REPORTS,
                match: [Paths.DEVELOPER_CALCULATE_REPORTS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'e8',
                title: 'backoffice.menu.postDeploymentActions',
                url: Paths.DEVELOPER_POST_DEPLOYMENT_ACTIONS,
                match: [Paths.DEVELOPER_POST_DEPLOYMENT_ACTIONS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            },
            {
                id: 'e9',
                title: 'backoffice.menu.dbConnections',
                url: Paths.DEVELOPER_DB_CONNECTIONS,
                match: [Paths.DEVELOPER_DB_CONNECTIONS],
                permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW },
                projectType: [ PROJECT_TYPE.AGENT_SYSTEM, PROJECT_TYPE.RETAIL_SYSTEM ],
                userRole: [USER_ROLE.ADMIN]
            }
        ]
    }
])
