import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Row, Col, Spin, Checkbox, Switch, message, Divider } from 'antd';

import Input from 'components/common/input';
import NumericInput from 'components/common/numericInput';

import TabFormDashboardLayout from "components/layouts/tab/form";

import { getTerminalGeneralInfo, saveTerminalGeneralInfo } from './api';

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';
import { numberTransform } from 'utils/common';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { PROJECT_PROVIDER_TYPE } from 'constants/project.constants';

import sportImg from "assets/images/sport.png";
import virtualSportImg from "assets/images/virtual.png"
import TerminalActions from "pages/retail/terminals/edit/sections/general/components/terminalActions";

/** Terminal Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [generalInfo, setGeneralInfo] = useState({});
    const [isFormTouched, setIsFormTouched] = useState(false);

    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.TERMINAL_GENERALINFO, action: PERMISSION_ACTION.MODIFY });

    /** Load terminal general info */
    useEffect(() => {
        setIsLoading(true)
        getTerminalGeneralInfo(searchParams.id)
            .then(value => {
                setGeneralInfo(value);
                setFieldsValue({
                    licenseNumber: value.licenseNumber,
                    serialNumber: value.serialNumber,
                    basketCountLimit: value.basketCountLimit,
                    name: value.name,
                    providers: value.providers.reduce((acc, cur) => {
                        acc[`provider_${cur.retailProvider}`] = cur.isEnabled;
                        return acc;
                    }, {})
                })

            })
            .finally(() => {
                setIsLoading(false)
            })
    }, []);

    const handleForm = () => {
        validateFields()
            .then(data => {
                setIsSaving(true);
                const d = {
                    id: searchParams.id,
                    licenseNumber: data.licenseNumber,
                    serialNumber: data.serialNumber,
                    basketCountLimit: data.basketCountLimit,
                    providers: Object.keys(data.providers ?? []).map(provider => ({
                        isEnabled: data.providers[provider],
                        retailProvider: provider.replace("provider_", "")
                    }))
                }

                saveTerminalGeneralInfo(d)
                    .then(({value, message: msg}) => {
                        message.success(msg)
                        setGeneralInfo(value);
                        setIsFormTouched(false);
                    })
                    .finally(() => {
                        setIsSaving(false)
                    })

            }).catch(err => {
                console.log(err)
            })
    }

    const onFormValuesChange = (_, formValues) => {
        const initialValues = {
            name: generalInfo.name,
            licenseNumber: generalInfo.licenseNumber,
            serialNumber: generalInfo.serialNumber,
            basketCountLimit: generalInfo.basketCountLimit,
            providers: generalInfo.providers.reduce((acc, cur) => {
                acc[`provider_${cur.retailProvider}`] = cur.isEnabled;
                return acc;
            }, {})
        }

        const values = { ... formValues };

        setIsFormTouched(isFormChanged(initialValues, values));
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    const handleStatusChange = (status) => {
        setGeneralInfo(state => ({
            ...state,
            status
        }));
    }


    const makeProviderBanner = provider => {
        const mapping = {
            [PROJECT_PROVIDER_TYPE.SPORTBOOK] : sportImg,
            [PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS] : virtualSportImg,
        }

        const path = mapping[provider];
        if(!path){
            return null;
        }
        return path;
    }

    const renderProvider = provider => (
        <div className='rt--widget'>
            <div className='rt--widget-header rt--pl-16 rt--pr-8 rt--flex rt--align-center rt--justify-between'>
                <b className='rt--title rt--font-big rt--font-extra-bold'>
                    {
                        provider.retailProvider === PROJECT_PROVIDER_TYPE.SPORTBOOK ? t("backoffice.terminals.sport"):
                        provider.retailProvider === PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS ? t("backoffice.terminals.virtualSport"): ""
                    }
                </b>
                <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between">
                    <Form.Item
                        name={["providers", `provider_${provider.retailProvider}`]}
                        valuePropName="checked"
                        className='rt--form-item-without-margin'
                    >
                        <Switch
                            disabled={!hasModifyPermission}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className='rt--widget-content'>
                <img
                    src={ makeProviderBanner(provider.retailProvider) }
                    alt={provider.retailProvider}
                />
            </div>
        </div>
    )

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.TERMINAL_GENERALINFO, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
            id={generalInfo.id}
            longId={generalInfo.longId}
            actions={<TerminalActions terminal={generalInfo} onStatusChange={handleStatusChange} />}
        >

            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={onFormValuesChange}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className='rt--flex rt--justify-start rt--align-center rt--mb-8'>
                                <b className='rt--pr-4 rt--font-bold rt--font-normal'>{t('backoffice.terminals.betshop')}:</b>
                                <span className='rt--font-regular rt--font-normal'>{generalInfo.betShopName}</span>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className='rt--flex rt--justify-start rt--align-center rt--mb-8'>
                                <b className='rt--pr-4 rt--font-bold rt--font-normal'>{t('backoffice.terminals.betshopId')}:</b>
                                <span className='rt--font-regular rt--font-normal'>{generalInfo.betShopLongId}</span>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className='rt--flex rt--justify-start rt--align-center rt--mb-8'>
                                <b className='rt--pr-4 rt--font-bold rt--font-normal'>{t('backoffice.terminals.address')}:</b>
                                <span className='rt--font-regular rt--font-normal'>{generalInfo.address}</span>
                            </div>
                        </Col>
                        <Col span={24}>
                            <h4 className='rt--form-section-title rt--title rt--mb-24 rt--mt-8 rt--font-bold rt--font-biger'>
                                {
                                     t("backoffice.projects.terminal")
                                }
                            </h4>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.terminals.name')} *`}
                                name="name"
                                className='rt--general-form-item rt--form-item-disabled'
                            >
                                <Input disabled={true}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.terminals.licenseNumber')}
                                name="licenseNumber"
                                className={'rt--general-form-item' + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t("backoffice.common.enter")} ${t("backoffice.terminals.licenseNumber")}`}
                            >
                                <Input
                                    disabled={!hasModifyPermission}
                                    placeholder={`${t("backoffice.common.enter")} ${t("backoffice.terminals.licenseNumber")}`}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.terminals.serialNumber')}
                                name="serialNumber"
                                className={'rt--general-form-item' + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t("backoffice.common.enter")} ${t("backoffice.terminals.serialNumber")}`}
                            >
                                <Input
                                    disabled={!hasModifyPermission}
                                    placeholder={`${t("backoffice.common.enter")} ${t("backoffice.terminals.serialNumber")}`}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.terminals.basketCountLimit')}
                                name="basketCountLimit"
                                className={'rt--general-form-item' + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.terminals.basketCountLimit')}`}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            const parentValue = generalInfo.parentBasketCountLimit;
                                            if (value !== null && value !== "" && parentValue !== null) {
                                                if (Number(value) > parentValue) {
                                                    return Promise.reject(t('backoffice.validation.mustBeLess').replace("%X%", parentValue))
                                                } else {
                                                    return Promise.resolve()
                                                }
                                            }
                                            return Promise.resolve()
                                        }
                                    })
                                ]}
                            >
                                <NumericInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.terminals.basketCountLimit')}`}
                                    disabled={!hasModifyPermission}
                                    isInteger={true}
                                    maxLength={10}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        generalInfo.providers?.length > 0 && (
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <h4 className='rt--form-section-title rt--title rt--mb-24 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {
                                            t("backoffice.terminals.featuredProducts")
                                        }
                                    </h4>
                                </Col>
                                {
                                    generalInfo.providers.map(provider => (
                                        <Col xs={24} xl={6} key={provider.retailProvider}>
                                            {
                                                renderProvider(provider)
                                            }
                                        </Col>

                                    ))
                                }
                            </Row>
                        )
                    }

                </Form>
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

export default GeneralInfoComponent;
