import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox, Col, Form, message, Row, Spin } from "antd";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import Tooltip from "components/common/tooltip";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

import { getTerminalSettings, saveTerminalSettings } from "../api";

const Main = ({
    onTabChange
}) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [terminalSettings, setTerminalSettings] = useState({});

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_TERMINALS, action: PERMISSION_ACTION.MODIFY });

    useEffect(() => {
        setIsLoading(true)
        getTerminalSettings()
            .then(value => {
                setTerminalSettings(value);
                setFieldsValue(value)
            })
            .finally(value => {
                setIsLoading(false)
            })
    }, [])

    const onFormValuesChange = (changed, formValues) => {
        const initialValues = { ...terminalSettings }

        const values = { ... formValues };

        setIsFormTouched(isFormChanged(initialValues, values));
    }

    const handleForm = () => {
        validateFields()
            .then(data => {
                setIsSaving(true);
                saveTerminalSettings(data)
                    .then(({value, message: msg}) => {
                        message.success(msg)
                        setTerminalSettings(value);
                        setIsFormTouched(false);
                    })
                    .finally(() => {
                        setIsSaving(false)
                    })

            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    const fields = [
        "enableCash", "enableVoucher", "depositWinningOnlineBalance", "topUpBalanceWithTicket",
        "partialWithdrawal", "withdrawWithVoucher", "withdrawToOnlineBalance",
    ]

    const renderField = field => (
        <div className="rt--flex rt--align-center rt--mb-16" key={field}>
            <Form.Item
                className='rt--form-item-without-margin rt--form-item-inline'
                name={field}
                valuePropName='checked'
            >
                <Checkbox />
            </Form.Item>
            <div className='rt--flex rt--align-center'>
                <span className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--pr-4'>{t(`backoffice.projects.${field}`)}</span>
                <Tooltip
                    title={t(`backoffice.projects.${field}Info`)}
                    trigger={["hover", "click"]}
                    enableMobile={true}
                >
                    <i className='icon-info rt--font-big' />
                </Tooltip>
            </div>
        </div>
    )

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={onFormValuesChange}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <h4 className='rt--form-section-title rt--title rt--mb-24 rt--mt-8 rt--font-bold rt--font-biger'>
                                {
                                     t("backoffice.projects.configuration")
                                }
                            </h4>
                        </Col>
                        <Col xs={24} sm={24} xl={14}>
                            <Row gutter={[16, 0]}>
                                <Col xs={24} md={12} xl={12}>
                                    <div className='rt--checkbox-group-list'>
                                        {
                                            fields.slice(0, 4).map(field => renderField(field))
                                        }
                                    </div>
                                </Col>
                                <Col xs={24} md={12} xl={12}>
                                    <div className='rt--checkbox-group-list'>
                                        {
                                            fields.slice(4).map(field => renderField(field))
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

export default Main;
