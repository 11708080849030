import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Spin, Checkbox, Radio, Row, Col } from 'antd';

import { saveProjectPaymentSettings } from "store/actions/dashboard/projects/paymentsConfig.action";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import Tooltip from 'components/common/tooltip';

import { hasPermission } from 'utils/permissions';
import {hasValue, isMobile} from 'utils/common';
import {
    constructFinaleData,
    constructFormData,
    doesUserMadeChanges
} from 'utils/payments';

import { PAYMENT_METHODS, WALLET_CONFIGS } from "constants/project.constants"
import { BANK_EPOS_VALUE, FIELD_NAMES } from 'constants/payments.constants';
import { WALLET_TYPE } from 'constants/wallet.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';

import projectPaymentConfigType from 'types/project/projectPaymentConfig.type';

/** Project Edit Page Integration Tab Component */
const ProjectPaymentConfigsComponent = ({
    saveProjectPaymentSettings,
    isSaving,
    isLoading,
    config,
    onTabChange
}) => {
    const { t } = useTranslation();

    const [selectedPaymentType, setSelectedPaymentType] = useState(PAYMENT_METHODS.STANDART);
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const {
        validateFields,
        setFieldsValue,
        getFieldValue,
        getFieldsValue
    } = formInstance;

    const isStandardTypeSelected = selectedPaymentType === PAYMENT_METHODS.STANDART;

    const hasProjectPaymentModifyPermission = hasPermission({
        resource: PERMISSION_RESOURCE.PROJECT_PAYMENT,
        action: PERMISSION_ACTION.MODIFY,
    })

    const handlePaymentTypeChange = ({ target: { value } }) => {
        setSelectedPaymentType(value);

        if (value === PAYMENT_METHODS.STANDART) {
            return;
        }

        const isEposOrBankSelected = getFieldValue(FIELD_NAMES.BANK) || getFieldValue(FIELD_NAMES.EPOS);

        if (isEposOrBankSelected) {
            return;
        }

        setFieldsValue({ [FIELD_NAMES.EPOS]: true });
    }

    const handleFormValuesChange = (changedFieldValue, fieldsValue) => {
        if (!isStandardTypeSelected) {
            const isEposAndBankNotSelected = (
                !fieldsValue[FIELD_NAMES.BANK] &&
                !fieldsValue[FIELD_NAMES.EPOS]
            )

            if (isEposAndBankNotSelected) {
                const changedFieldName = Object.keys(changedFieldValue)[0];

                setFieldsValue({ [changedFieldName]: true });

                return;
            }
        }

        setIsFormTouched(doesUserMadeChanges(
            { ...fieldsValue },
            selectedPaymentType,
            config,
        ))
    }

    const handleForm = () => {
        validateFields()
            .then(fieldsValue => {
                const requestBody = constructFinaleData(fieldsValue, selectedPaymentType);

                saveProjectPaymentSettings(requestBody);
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    // Set form fields values, when data is loaded
    useEffect(() => {
        if (!hasValue(config)) {
            return;
        }

        const { formFieldsValue, selectedPaymentType } = constructFormData(config);

        setFieldsValue({
            ...formFieldsValue
        });
        setSelectedPaymentType(selectedPaymentType)
    }, [config])

    // Check whether the user made changes or not
    useEffect(() => {
        if (!hasValue(config)) {
            return;
        }

        setIsFormTouched(doesUserMadeChanges(
            { ...getFieldsValue() },
            selectedPaymentType,
            config,
        ))
    }, [selectedPaymentType]);

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={[{
                type: "primary",
                text: t("backoffice.common.save"),
                enabled: hasProjectPaymentModifyPermission,
                loading: isSaving,
                disabled: !isFormTouched,
                onClick: handleForm,
            }]}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={handleFormValuesChange}
                    initialValues={{
                        [FIELD_NAMES.BANK]: false,
                        [FIELD_NAMES.EPOS]: true,
                        [FIELD_NAMES.WALLET_TYPES]: WALLET_TYPE.BALANCE,
                        [FIELD_NAMES.HIDE_HISTORY]: false,
                        [FIELD_NAMES.WALLET_CONFIGS]: WALLET_CONFIGS.RELATED,
                    }}
                >
                    <Row>
                        <Col xxl={8} xl={10} lg={12} md={16} sm={24} xs={24}>
                            <Radio.Group
                                disabled={!hasProjectPaymentModifyPermission}
                                onChange={handlePaymentTypeChange}
                                value={selectedPaymentType}
                                style={{ width: "100%" }}
                            >
                                <div className='rt--payment-section'>
                                    <Radio value={PAYMENT_METHODS.STANDART}>
                                        {t("backoffice.payments.standart")}
                                    </Radio>

                                    <div className='rt--payment-section-sub'>
                                        <Form.Item
                                            name={FIELD_NAMES.WALLET_CONFIGS}
                                            className='rt--form-item-without-height rt--form-item-without-margin'
                                            label={
                                                <span className='rt--payment-section-sub-block-label'>
                                                    {t("backoffice.payments.walletConfigurations")}
                                                </span>
                                            }
                                        >
                                            <Radio.Group disabled={!isStandardTypeSelected || !hasProjectPaymentModifyPermission}>
                                                <div className={`rt--payment-section-sub-block-items${isMobile() ? '-mobile' : ''}`}>
                                                    <Radio value={WALLET_CONFIGS.RELATED}>
                                                        <div className='rt--payment-section-sub-block-items-item'>
                                                            {t("backoffice.payments.relatedWallets")}
                                                            <Tooltip
                                                                title={t("backoffice.payments.relatedWalletsTooltip")}
                                                                trigger={["hover", "click"]}
                                                                placement="top"
                                                                enableMobile={true}
                                                            >
                                                                <i className='icon-info' />
                                                            </Tooltip>
                                                        </div>
                                                    </Radio>

                                                    <Radio value={WALLET_CONFIGS.NON_RELATED}>
                                                        <div className='rt--payment-section-sub-block-items-item'>
                                                            {t("backoffice.payments.nonRelatedWallets")}
                                                            <Tooltip
                                                                title={t("backoffice.payments.nonRelatedWalletsTooltip")}
                                                                trigger={["hover", "click"]}
                                                                placement="top"
                                                                enableMobile={true}
                                                            >
                                                                <i className='icon-info' />
                                                            </Tooltip>
                                                        </div>
                                                    </Radio>
                                                </div>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className='rt--payment-section'>
                                    <Radio value={BANK_EPOS_VALUE}>
                                        {t("backoffice.payments.bankEpos")}
                                    </Radio>

                                    <div className='rt--payment-section-sub'>
                                        <Form.Item
                                            name={FIELD_NAMES.EPOS}
                                            className='rt--form-item-without-height rt--form-item-without-margin'
                                            valuePropName='checked'
                                        >
                                            <Checkbox disabled={isStandardTypeSelected || !hasProjectPaymentModifyPermission}>
                                                {t("backoffice.payments.epos")}
                                            </Checkbox>
                                        </Form.Item>

                                        <div className='rt--payment-section-sub-block'>
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) => {
                                                    const isEposSelected = getFieldValue(FIELD_NAMES.EPOS);

                                                    return (
                                                        <Form.Item
                                                            name={FIELD_NAMES.WALLET_TYPES}
                                                            className='rt--form-item-without-height rt--form-item-without-margin'
                                                            label={
                                                                <span className='rt--payment-section-sub-block-label'>
                                                                    {t("backoffice.payments.transactionWallet")}
                                                                </span>
                                                            }
                                                        >
                                                            <Radio.Group disabled={!isEposSelected || isStandardTypeSelected || !hasProjectPaymentModifyPermission}>
                                                                <div className={`rt--payment-section-sub-block-items${isMobile() ? '-mobile' : ''}`}>
                                                                    <Radio value={WALLET_TYPE.BALANCE}>
                                                                        <div className='rt--payment-section-sub-block-items-item'>
                                                                            {t("backoffice.common.balance")}
                                                                            <Tooltip
                                                                                title={t("backoffice.payments.balanceWalletHint")}
                                                                                trigger={["hover", "click"]}
                                                                                placement="top"
                                                                                enableMobile={true}
                                                                            >
                                                                                <i className='icon-info' />
                                                                            </Tooltip>
                                                                        </div>
                                                                    </Radio>

                                                                    <Radio value={WALLET_TYPE.CREDIT_LINE}>
                                                                        <div className='rt--payment-section-sub-block-items-item'>
                                                                            {t("backoffice.common.creditLine")}
                                                                            <Tooltip
                                                                                title={t("backoffice.payments.creditLineWalletHint")}
                                                                                trigger={["hover", "click"]}
                                                                                placement="top"
                                                                                enableMobile={true}
                                                                            >
                                                                                <i className='icon-info' />
                                                                            </Tooltip>
                                                                        </div>
                                                                    </Radio>
                                                                </div>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    )
                                                }}
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className='rt--payment-section-sub'>
                                        <Form.Item
                                            name={FIELD_NAMES.BANK}
                                            className='rt--form-item-without-height rt--form-item-without-margin'
                                            valuePropName='checked'
                                        >
                                            <Checkbox
                                                value={PAYMENT_METHODS.BANK_TRANSFER}
                                                disabled={isStandardTypeSelected || !hasProjectPaymentModifyPermission}
                                            >
                                                {t("backoffice.payments.bankTransfer")}
                                            </Checkbox>
                                        </Form.Item>
                                    </div>

                                    <div className='rt--payment-section-footer'>
                                        {!isStandardTypeSelected && (
                                            <Form.Item
                                                name={FIELD_NAMES.HIDE_HISTORY}
                                                className='rt--form-item-without-height rt--form-item-without-margin'
                                                valuePropName='checked'
                                            >
                                                <Checkbox disabled={isStandardTypeSelected || !hasProjectPaymentModifyPermission}>
                                                    <div className='rt--payment-section-footer-item'>
                                                        {t("backoffice.payments.hidePaymentHistory")}
                                                        <Tooltip
                                                            title={t("backoffice.payments.hidePaymentHistoryHint")}
                                                            trigger={["hover", "click"]}
                                                            placement="top"
                                                            enableMobile={true}
                                                        >
                                                            <i className='icon-info' />
                                                        </Tooltip>
                                                    </div>
                                                </Checkbox>
                                            </Form.Item>
                                        )}
                                    </div>
                                </div>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** ProjectPaymentConfigsComponent propTypes
    * PropTypes
*/
ProjectPaymentConfigsComponent.propTypes = {
    /** Redux action to save project project payment */
    saveProjectPaymentSettings: PropTypes.func,
    /** Redux state property, is true when project payment is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when project payment is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, current editing project payment type */
    config: projectPaymentConfigType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        saveProjectPaymentSettings: data => {
            dispatch(saveProjectPaymentSettings(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
        config: state.projects.edit.payment.config
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPaymentConfigsComponent)
