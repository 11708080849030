import React, {Fragment, useState} from 'react';
import Select from "components/common/select";
import {Form, Space} from "antd";

const SearchWithSelect = ({ value, selectName = "", selectOptions = [], controls = {}, onChange = () => {} }) => {
    const [selectValue, setSelectValue] = useState(selectOptions[0]?.value);

    const handleSelectChange = (value) => {
        setSelectValue(value);

        onChange?.("");
    }

    const currentControl = controls[selectValue]?.({value, selectValue, onChange});

    return (
        <Space.Compact className="rt--search-with-select">
            <Form.Item name={selectName} noStyle>
                <Select value={selectValue} options={selectOptions} onChange={handleSelectChange} />
            </Form.Item>

            {currentControl}
        </Space.Compact>
    );
};

export default SearchWithSelect;
