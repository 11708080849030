import React, {useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import useAutosuggestion from "hooks/useAutosuggestion";
import {AUTOSUGGESTION_TYPE} from "constants/autosuggestion.constants";
import useUserCurrencies from "hooks/useUserCurrencies";
import FiltersWrapper from "components/common/filters";
import {Col, Form, Row, Space} from "antd";
import DateRangePicker from "components/common/dateRangePicker";
import {TIME_PICKER_MODES} from "components/common/dateRangePicker/constants";
import Select from "components/common/select";
import {
    getVoucherHistoryReport,
    setVoucherHistoryReportFilters
} from "store/actions/dashboard/reports/voucherHistoryReport.action";
import {connect} from "react-redux";
import {VOUCHER_STATE} from "constants/voucher.contants";
import getUserTypeOptions from "pages/reports/productReport/helpers/getUserTypeOptions";
import {FILTER_USER_TYPE, USER_ROLE, USER_TYPE} from "constants/user.constants";
import Search from "components/common/search";
import {isMobile} from "utils/common";
import SearchWithSelect from "components/common/searchWithSelect";
import AutoComplete from "components/common/autoComplete";
import {DATE_PICKER_RANGES} from "constants/common.constants";

const FILTERS_FIELD_NAMES = {
    DATE: "date",
    BET_SHOP_NAME_OR_ID: "betShopNameOrId",
    STATUS: "status",
    FILTER_TYPE: "filterType"
}

const Filters = ({
    globalProjectId,
    filters,
    getVoucherHistoryReport,
    setVoucherHistoryReportFilters
}) => {
    const { t } = useTranslation();

    const [ betshopNames, getBetshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP,
        autoGet: false
    });
    const [ cashierNames, getCashierNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.CASHIER,
        autoGet: false
    });
    const [ terminalNames, getTerminalNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.TERMINAL,
        autoGet: false
    });

    const statusOptions = useMemo(() => [
        {
            text: t("backoffice.common.all"),
            value: ""
        },
        {
            text: t('backoffice.common.active'),
            value: VOUCHER_STATE.ACTIVE,
        },
        {
            text: t('backoffice.common.used'),
            value: VOUCHER_STATE.USED,
        },
        {
            text: t('backoffice.common.expired'),
            value: VOUCHER_STATE.EXPIRED,
        },
        {
            text: t('backoffice.common.paidOut'),
            value: VOUCHER_STATE.PAID_OUT,
        }
    ], [t]);

    const createdByOptions = useMemo(() => [
        {
            text: t('backoffice.reports.cashier'),
            value: USER_TYPE.CASHIER
        },
        {
            text: t('backoffice.reports.terminal'),
            value: USER_TYPE.TERMINAL
        }
    ], [t]);

    const filterOption = (options) => (search) => {
        return options.filter(
            item => (
                item.name.toLowerCase().includes(search.toLowerCase()) ||
                item.longId.toString().includes(search)
            )
        );
    };

    return (
        <FiltersWrapper
            loadFn={getVoucherHistoryReport}
            setFiltersFn={setVoucherHistoryReportFilters}
            filters={filters}
            updateProp={[globalProjectId]}
            searchFieldName="id"
            datePickerFields={[
                { name: FILTERS_FIELD_NAMES.DATE, time: true }
            ]}
            onInit={() => {
                getBetshopNames();
                getCashierNames();
                getTerminalNames();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.date')}
                        name={FILTERS_FIELD_NAMES.DATE}
                    >
                        <DateRangePicker
                            showTime={true}
                            timePickerMode={TIME_PICKER_MODES.ONLY_HOURS}
                            allowClear={false}
                            enabledMountsCount={12}
                            disabledRanges={[DATE_PICKER_RANGES.LAST_YEAR]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.betShopName')}
                        name={FILTERS_FIELD_NAMES.BET_SHOP_NAME_OR_ID}
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t("backoffice.common.all") },
                                    ...betshopNames.map(b => (
                                        { value: b.id, text: b.name }
                                    ))
                                ]
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.betshop')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.createdBy')}
                        name="createdBy"
                    >
                        <SearchWithSelect
                            selectName="creatorType"
                            selectOptions={createdByOptions}
                            controls={{
                                [USER_TYPE.CASHIER]: ({value, onChange}) => (
                                    <Select
                                        key={USER_TYPE.CASHIER}
                                        search
                                        value={value}
                                        options={[
                                            { value: "", text: t("backoffice.common.all") },
                                            ...cashierNames.map(cashierName => ({ text: cashierName.name, value: cashierName.id }))
                                        ]}
                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.reports.cashierUsernameOrId')}`}
                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                        filterOption
                                        onChange={onChange}
                                    />
                                    ),
                                [USER_TYPE.TERMINAL]: ({value, onChange}) => (
                                    <Select
                                        key={USER_TYPE.TERMINAL}
                                        search
                                        value={value}
                                        options={[
                                            { value: "", text: t("backoffice.common.all") },
                                            ...terminalNames.map(terminalName => ({ text: terminalName.name, value: terminalName.id }))
                                        ]}
                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.reports.terminalNameOrId')}`}
                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                        onChange={onChange}
                                    />
                                )
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.status')}
                        name={FILTERS_FIELD_NAMES.STATUS}
                    >
                        <Select
                            options={statusOptions}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    );
};

const mapDispatchToProps = dispatch => (
    {
        getVoucherHistoryReport: () => {
            dispatch(getVoucherHistoryReport());
        },
        setVoucherHistoryReportFilters: filters => {
            dispatch(setVoucherHistoryReportFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.voucherHistoryReport.filters,
        globalProjectId: state.common.globalProjectId,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Filters);
