import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import { Form } from 'antd';

import Modal from 'components/common/modal';
import Select from 'components/common/select';
import ImageUploader from 'components/common/imageUploader';

import { isFunction } from 'utils/common';
import { TERMINAL_SPORTBOOKS_ITEM, TERMINAL_SPORTBOOKS_ITEM_TRANSLATIONS, TERMINAL_VIRTUAL_SPORT_ITEM, TERMINAL_VIRTUAL_SPORT_ITEM_TRANSLATIONS } from 'constants/terminalCustomize.constants';
import { PROJECT_PROVIDER_TYPE } from 'constants/project.constants';
import { IMAGE_TYPE } from 'constants/common.constants';

const FORM_FIELD_NAMES = {
    PROVIDER: "provider",
    PRODUCT: "product",
    FILE: "file",
}

const ProductAddComponent = ({
    onClose,
    onOk,
    isSaving,
    data
}) => {
    const { t } = useTranslation();

    const [ selectedProvider, setSelectedProvider ] = useState(null)
    const [ selectedItem, setSelectedItem ] = useState(null)

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const productOptions = useMemo(() => {
        if(selectedProvider === null) return [];
        let options = [];
        if(selectedProvider === PROJECT_PROVIDER_TYPE.SPORTBOOK){
            options = [
                { value: "*", text: t("backoffice.terminals.sport")},
                ...Object.values(TERMINAL_SPORTBOOKS_ITEM).map(item => (
                    { value: item, text: TERMINAL_SPORTBOOKS_ITEM_TRANSLATIONS[item]}
                ))
            ]
        } else if( selectedProvider === PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS){
            options = [
                {value: "*", text: t("backoffice.terminals.virtualSport")},
                ...Object.values(TERMINAL_VIRTUAL_SPORT_ITEM).map(item => (
                    { value: item, text: TERMINAL_VIRTUAL_SPORT_ITEM_TRANSLATIONS[item]}
                ))
            ]
        }
        const alreadySelectedProducts = data.filter(item => item.provider === selectedProvider).map(item => item.product ? item.product : "*");
        return options.filter(option => !alreadySelectedProducts.includes(option.value))

    }, [selectedProvider, t, data])

    const handleProviderChange = value => {
        setSelectedProvider(value);

        setFieldsValue({
            [FORM_FIELD_NAMES.PRODUCT] : undefined
        })
    }

    const handleForm = () => {
        validateFields()
            .then(fieldsValue => {
                if (isFunction(onOk)) {
                    onOk({
                        ...fieldsValue,
                        [FORM_FIELD_NAMES.PRODUCT] : fieldsValue[FORM_FIELD_NAMES.PRODUCT] === "*" ? "" : fieldsValue[FORM_FIELD_NAMES.PRODUCT],
                        [FORM_FIELD_NAMES.FILE]: fieldsValue[FORM_FIELD_NAMES.FILE]
                    });
                    onClose();
                }

            }).catch(ex => {
                console.log(ex)
            })
    }

    const handleItemChange = value => {
        setSelectedItem(value);
    }

    return (
        <Modal
            title={t('backoffice.terminals.addProduct')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            disableOkButton={selectedProvider === null || selectedItem === null}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >

                <Form.Item
                    label={`${t('backoffice.terminals.provider')} *`}
                    name={FORM_FIELD_NAMES.PROVIDER}
                    rules={[
                        { required: true, message: t('backoffice.validation.fieldRequired') },
                    ]}
                >
                    <Select
                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.terminals.provider')}`}
                        options={[
                            { value: PROJECT_PROVIDER_TYPE.SPORTBOOK, text: t("backoffice.terminals.sport")},
                            { value: PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS, text: t("backoffice.terminals.virtualSport")}
                        ]}
                        onChange={handleProviderChange}
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                    />
                </Form.Item>

                <Form.Item
                    label={`${t('backoffice.terminals.item')} *`}
                    name={FORM_FIELD_NAMES.PRODUCT}
                    className={selectedProvider === null ? " rt--form-item-disabled" : ""}
                    rules={[
                        { required: true, message: t('backoffice.validation.fieldRequired') },
                    ]}
                >
                    <Select
                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.terminals.item')}`}
                        options={productOptions}
                        disabled={selectedProvider === null}
                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        onChange={handleItemChange}
                    />
                </Form.Item>

                <Form.Item
                    label=""
                    name={FORM_FIELD_NAMES.FILE}
                    className='rt--general-form-item rt--form-item-without-margin rt--mt-4'
                >
                    <ImageUploader
                        remove={true}
                        size={3 * 1024 * 1024}
                        acceptedFormats={ [ IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG, IMAGE_TYPE.WEBP, IMAGE_TYPE.SVG ]}
                        removeWithoutConfirmation={true}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

/** ProductAddComponent propTypes
    * PropTypes
*/
ProductAddComponent.propTypes = {
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Fires on popup add button clicked */
    onOk: PropTypes.func,
    /** true when saving data */
    isSaving: PropTypes.bool,

}

export default ProductAddComponent;