import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Input, Spin, Divider } from 'antd';

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import Select from "components/common/select";

import { getProjectIntegration, saveProjectIntegration } from "store/actions/dashboard/projects/integration.action";

import projectIntegrationType from "types/project/integration.type";

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { PROJECT_INTEGRATION_MODE } from 'constants/project.constants';


/** Project Edit Page Integration Tab Component */
const IntegrationComponent = ({
    getProjectIntegration,
    saveProjectIntegration,
    isSaving,
    isLoading,
    integration,
    onTabChange
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const canEdit = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_INTEGRATION, action: PERMISSION_ACTION.MODIFY });

    /** Set current editing project id as global project id */
    useEffect(() => {
        getProjectIntegration()
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            mode: integration.mode,
            version: integration.version
        });
    }, [integration])

    /** Fires when form submitted
       * @function
       * @memberOf IntegrationComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveProjectIntegration({
                    version: data.version,
                    mode: data.mode
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={
                canEdit
                    ? [
                        {
                            type: "primary",
                            onClick: handleForm,
                            text: t("backoffice.common.save"),
                            enabled: true,
                            loading: isSaving,
                            disabled: !isFormTouched
                        }
                    ]
                    : []
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        version: integration.version,
                        mode: integration.mode
                    }}
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({
                        version: formValues.version,
                        mode: formValues.mode
                    }, {
                        version: integration.version,
                        mode: integration.mode
                    }))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.projects.name')}
                                className='rt--form-item-disabled'
                            >
                                <Input
                                    value={integration.name}
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.projects.gateway')}
                                className='rt--form-item-disabled'
                            >
                                <Input
                                    value={integration.gateway}
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                className={!canEdit ? "rt--form-item-disabled" : ""}
                                label={`${t('backoffice.projects.version')} *`}
                                name="version"
                            >
                                <Select
                                    options={
                                        (integration.allowedVersions || []).map(version => (
                                            { value: version, text: version}
                                        ))
                                    }
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.version')}`}
                                    disabled={!canEdit}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                className={!canEdit ? "rt--form-item-disabled" : ""}
                                label={`${t('backoffice.projects.mode')} *`}
                                name="mode"
                            >
                                <Select
                                    options={[
                                        { value: PROJECT_INTEGRATION_MODE.TEST, text: t('backoffice.projects.test') },
                                        { value: PROJECT_INTEGRATION_MODE.REAL, text: t('backoffice.projects.real') }
                                    ]}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.mode')}`}
                                    disabled={!canEdit}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider className='rt--form-section-divider' />
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label={t('backoffice.projects.documents')}
                            >
                                <div className='rt--flex rt--flex-col'>
                                    {
                                        Object.keys(integration.documents || []).map(d => (
                                            <div key={d} className='rt--pb-8'>
                                                <a
                                                    href={integration.documents[d]}
                                                    target="_blank" rel="noreferrer"
                                                    className='rt--font-capitalize'
                                                >{d}</a>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** IntegrationComponent propTypes
    * PropTypes
*/
IntegrationComponent.propTypes = {
    /** Redux action to save project integration */
    saveProjectIntegration: PropTypes.func,
    /** Redux state property, is true when integration is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when integration is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, current editing project integration */
    integration: projectIntegrationType,
    /** Redux action to get project integration */
    getProjectIntegration: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getProjectIntegration: () => {
            dispatch(getProjectIntegration());
        },

        saveProjectIntegration: data => {
            dispatch(saveProjectIntegration(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        integration: state.projects.edit.integration,
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationComponent)
