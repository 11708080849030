import React from "react";

import { Form, Switch } from "antd";

import "./styles.scss";
import {useTranslation} from "react-i18next";
import {AGENT_COMMISSION_STATE} from "constants/agent.constants";
import NumericInput from "components/common/numericInput";

const CommissionDetailsBaseRow = ({
	name,
	label,
	stateDisabled,
	maxPercent = 100,
	formInstance,
	onPercentChange,
	onStateChange
}) => {
	const stateValue = Form.useWatch([...name, "state"], formInstance) === AGENT_COMMISSION_STATE.ACTIVE;

	const { t } = useTranslation();

	const handleActionsContainerClick = (event) => {
		event.stopPropagation();
	};

	const normalizeStateValue = (checked) => {
		if (!checked) {
			formInstance.validateFields([[...name, "commissionPercent"]]);
		}

		return checked ? AGENT_COMMISSION_STATE.ACTIVE : AGENT_COMMISSION_STATE.INACTIVE;
	}

	const denormalizeStateValue = (value) => {
		return value === AGENT_COMMISSION_STATE.ACTIVE;
	};

	const validateMax = (value) => {
		if (!value || !stateValue) {
			return Promise.resolve();
		}

		if (Number(value) > maxPercent) {
			return Promise.reject(t("validation.mustBeLess").replace("%X%", maxPercent));
		}

		if (Number(value) > 100) {
			return Promise.reject(t("validation.mustBeLess").replace("%X%", 100));
		}

		return Promise.resolve();
	};

	const handlePercentChange = (value) => {
		onPercentChange?.(value);
	};

	return (
		<div className="rt--commission-details-row">
			<span className="rt--commission-details-row-label">{label}</span>

			<div className="rt--commission-details-row-controls" onClick={handleActionsContainerClick}>
				<Form.Item
					noStyle
					name={[...name, "state"]}
					normalize={normalizeStateValue}
					getValueProps={(value) => ({ checked: denormalizeStateValue(value) })}
				>
					<Switch disabled={stateDisabled} onChange={onStateChange} />
				</Form.Item>

				<Form.Item
					noStyle
					name={[...name, "commissionPercent"]}
					rules={[{ required: stateValue }, { validator: (_, value) => validateMax(value) }]}
				>
					<NumericInput
						className="rt--commission-details-row-controls-percent"
						addonAfter="%"
						onChange={handlePercentChange}
						disabled={!stateValue}
						emptyOnZero
						max={100}
					/>
				</Form.Item>
			</div>
		</div>
	);
};

export default CommissionDetailsBaseRow;
