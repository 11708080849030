import React from 'react';

export const getTableColumns = (additionalProps) => {
    const {
        onUserNameClick,
        navBarActiveItemTitle,
        hasLiteMode,
        formatAmount
    } = additionalProps;

    let columns = [
        {
            title: 'backoffice.performancereport.agentId',
            dataIndex: "id",
            copy: true,
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.performancereport.agentUserName",
            dataIndex: "agentUserName",
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 2,
            render: (value) => {
                if (navBarActiveItemTitle === value) {
                    return value;
                }

                return (
                    <span
                        className="rt--table-col-link"
                        onClick={(e) => onUserNameClick(value, e)}
                    >
                        <b>{value}</b>
                    </span>
                )
            }
        },
        {
            title: "backoffice.performancereport.agentLevel",
            dataIndex: "agentLevel",
            sorter: true,
            render: (agentLevel) => {
                return (
                    <div className={"rt--flex rt--align-center rt--table-col-with-icon"}>
                        <i className="icon-level rt--font-bigest" />
                        <span>{agentLevel}</span>
                    </div>
                )
            },
        },
        {
            title: "backoffice.performancereport.registrationDate",
            dataIndex: "agentRegistrationDate",
            isDateTime: true,
            sorter: true,
        },
        ...(
            !hasLiteMode
                ? [{
                    title: "backoffice.performancereport.currencies",
                    dataIndex: "currencies",
                    multi: true,
                    mobileLevel: 5,
                    disableExport: true,
                }]
                : []
          ),
        {
            title: "backoffice.performancereport.playerSignUps",
            dataIndex: "playerSignups",
            sorter: true,
            isNumeric: true,
            mobileLevel: 6,
        },
        {
            title: "backoffice.performancereport.firstTimeDepositCount",
            dataIndex: "firstTimeDepositCount",
            sorter: true,
            isNumeric: true,
            mobileLevel: 7,
        },
        {
            title: "backoffice.performancereport.depositCount",
            dataIndex: "depositCount",
            sorter: true,
            isNumeric: true,
        },
        ...(
            hasLiteMode
                ? [{
                    title: "backoffice.performancereport.depositAmount",
                    dataIndex: "depositAmount",
                    mobileLevel: 10,
                    withCurrencySymbol: hasLiteMode,
                    render: (value, record) => formatAmount(record.performanceDetails[0].depositAmount, record.performanceDetails[0].currencyCode)
                }]
                : []
        ),
        {
            title: "backoffice.performancereport.withdrawCount",
            dataIndex: "withdrawCount",
            sorter: true,
            isNumeric: true,
        },
        ...(
            hasLiteMode
                ? [{
                    title: "backoffice.performancereport.withdrawalAmount",
                    dataIndex: "withdrawAmount",
                    withCurrencySymbol: true,
                    render: (value, record) => <span className='rt--table-withdrawal'>{formatAmount(record.performanceDetails[0].withdrawAmount, record.performanceDetails[0].currencyCode)}</span>,
                    mobileLevel: 12
                }]
                : []
        ),
    ];

    if (hasLiteMode) {
        columns = [
            ...columns,
            {
                title: "backoffice.performancereport.netDepositAmount",
                dataIndex: "netDepositAmount",
                withCurrencySymbol: hasLiteMode,
                render: (value, record) => (
                    <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(record.performanceDetails[0].netDepositAmount, record.performanceDetails[0].currencyCode)}
                </span>
                ),
            },
            {
                title: "backoffice.performancereport.turnover",
                dataIndex: "turnover",
                mobileLevel: 4,
                withCurrencySymbol: hasLiteMode,
                render: (value, record) => formatAmount(record.performanceDetails[0].turnover, record.performanceDetails[0].currencyCode)
            },
            {
                title: "backoffice.performancereport.winAmount",
                dataIndex: "won",
                withCurrencySymbol: hasLiteMode,
                render: (value, record) => formatAmount(record.performanceDetails[0].won, record.performanceDetails[0].currencyCode)
            },
            {
                title: "backoffice.performancereport.ggr",
                dataIndex: "ggr",
                mobileLevel: 3,
                withCurrencySymbol: hasLiteMode,
                render: (value, record) => (
                    <span className={value < 0 ? "rt--error-text" : ""}>
                        {formatAmount(record.performanceDetails[0].ggr, record.performanceDetails[0].currencyCode)}
                    </span>
                ),
            },
        ]
    }

    return columns;
}

export const getExpandColumns = (additionalProps) => {
    const {
        formatAmount
    } = additionalProps;

    return [
        {
            title: "backoffice.performancereport.currency",
            dataIndex: "currencyCode",
            mobileLevel: 1,
        },
        {
            title: "backoffice.performancereport.playerSignUps",
            dataIndex: "playerSignups",
            isNumeric: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.performancereport.firstTimeDepositCount",
            dataIndex: "firstTimeDepositCount",
            isNumeric: true,
        },

        {
            title: "backoffice.performancereport.depositCount",
            dataIndex: "depositCount",
            mobileLevel: 3,
            isNumeric: true,
        },
        {
            title: "backoffice.performancereport.depositAmount",
            dataIndex: "depositAmount",
            mobileLevel: 4,
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title: "backoffice.performancereport.withdrawCount",
            dataIndex: "withdrawCount",
            isNumeric: true,
            mobileLevel: 5,
        },
        {
            title: "backoffice.performancereport.withdrawalAmount",
            dataIndex: "withdrawAmount",
            render: (value, record) => <span className='rt--table-withdrawal'>{formatAmount(value, record.currencyCode)}</span>,
            mobileLevel: 6
        },
        {
            title: "backoffice.performancereport.netDepositAmount",
            dataIndex: "netDepositAmount",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },
        {
            title: "backoffice.performancereport.turnover",
            dataIndex: "turnover",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title: "backoffice.performancereport.winAmount",
            dataIndex: "won",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title: "backoffice.performancereport.ggr",
            dataIndex: "ggr",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },
        {
            title: "backoffice.performancereport.otherCosts",
            dataIndex: "otherCosts",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title: "backoffice.performancereport.ngr",
            dataIndex: "ngr",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },
        {
            title: "backoffice.performancereport.networkCommissions",
            dataIndex: "networkCommissions",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title: "backoffice.performancereport.directCommissions",
            dataIndex: "directCommissions",
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
    ]
}
