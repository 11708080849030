import { useSelector } from 'react-redux';

const selectGlobalProjectId = state => state.common.globalProjectId;
const selectGlobalCompanyId = state => state.common.globalCompanyId;
const selectCompanies = state => state.profile.userInfo.companies;

const useCurrentProject = () => {
    const globalProjectId = useSelector(selectGlobalProjectId);
    const globalCompanyId = useSelector(selectGlobalCompanyId);
    const companies = useSelector(selectCompanies);

    const company = companies?.find(c => c.id === globalCompanyId);
    const project = (company?.projects ?? []).find(p => p.id === globalProjectId);
    return project;
}

export default useCurrentProject;