export const USER_STATE = {
    IN_PROGRESS: 1 << 0,
    ACTIVE: 1 << 1,
    BLOCKED: 1 << 2,
    EXPIRED: 1 << 3,
    DELETED: 1 << 4,
    LOCKED2FA: 1 << 5,
    LOCKEDLOGIN: 1 << 6,
}

export const USER_GENDER = {
    MALE: 1,
    FEMALE: 2,
    NONE: 4,
}

export const SECURITY_LEVELS = [ 1, 2, 3, 4, 5, 6, 7]

export const USER_TYPE = {
    ADMIN: 1,
    AGENT: 2,
    CASHIER: 4,
    SUPERAGENT: 8,
    BETSHOP_MANAGER: 16,
    AGENT_PLAYER: 32,
    PLAYER: 64,
    ACCESS_MANAGER: 128,
    TERMINAL: 256,
}

export const FILTER_USER_TYPE = {
    AGENT: 1,
    PLAYER: 2
}

export const USER_ROLE = {
    ADMIN: "Admin",
    AGENT: "Agent",
    AGENT_PLAYER: "AgentPlayer",
    ACCESS_MANAGER: "AccessManager"
}
