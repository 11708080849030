import i18n from "translations/config";

export const getTableColumns = (additionalProps) => {
    const {
        excludedColumns,
        dateService
    } = additionalProps;

    const columns = [
        {
            title: "backoffice.sessions.sessionId",
            dataIndex: "id",
            copy: true,
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: 'backoffice.sessions.userName',
            dataIndex: 'userName',
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 6,
        },
        {
            title: 'backoffice.sessions.previousUserName',
            dataIndex: 'previousUserName',
            render: value => value || "-",
        },
        {
            title: 'backoffice.sessions.betshopName',
            dataIndex: 'betShopName',
            sorter: true,
            mobileLevel: 7,
        },
        {
            title: "backoffice.sessions.sessionStart",
            dataIndex: "startTime",
            isDateTime: true,
            sorter: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.sessions.sessionEnd",
            dataIndex: "endTime",
            isDateTime: true,
            sorter: true,
            mobileLevel: 3,
        },
        {
            title: 'backoffice.sessions.sessionDuration',
            dataIndex: 'duration',
            mobileLevel: 4,
            disableExport: true,
            render: (_, record) => dateService.humanizeDuration(dateService.getDifference(record.endTime, record.startTime, "milliseconds"))

        },
        {
            title: 'backoffice.sessions.loginIp',
            dataIndex: 'IP',
            sorter: true,
            mobileLevel: 5,
            render: (_, record) => record.ip
        },
        {
            title: 'backoffice.sessions.country',
            dataIndex: 'country',
            sorter: true,
            mobileLevel: 6,
            render: (value) => value ? i18n.t(`backoffice.countries.${value}`) : "-",
        }
    ]

    return (
        columns.filter(columnData => !excludedColumns.includes(columnData.dataIndex))
    )
}

