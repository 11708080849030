import React, {useMemo} from 'react';
import {isMobile} from "utils/common";
import TotalsComponent from "components/common/totals";
import {REPORT_TOTAL_TYPE} from "constants/reports.constants";
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import {
    getVoucherHistoryReport, getVoucherHistoryReportTotals,
    setVoucherHistoryReportFilters, setVoucherHistoryReportSorting
} from "store/actions/dashboard/reports/voucherHistoryReport.action";
import {connect} from "react-redux";
import Filters from "pages/reports/voucherHistoryReport/filters";
import {AUTOSUGGESTION_TYPE} from "constants/autosuggestion.constants";
import {useTranslation} from "react-i18next";
import {tableColumnsCreator} from "utils/tableColumnsCreator";
import getTableColumns from "pages/reports/voucherHistoryReport/helpers/getTableColumns";
import useIncludedColumns from "hooks/useIncludedColumns";
import {VOUCHER_HISTORY_REPORT} from "constants/pageName.constants";
import useFormat from "hooks/useFormat";
import useDate from "hooks/useDate";
import ApiUrls from "constants/api.constants";
import {hasPermission} from "utils/permissions";
import {PERMISSION_ACTION, PERMISSION_RESOURCE} from "constants/permissions.constants";

const VoucherHistoryReport = ({
    globalProjectId,
    isLoading,
    report,
    total,
    sorting,
    filters,
    getVoucherHistoryReport,
    setVoucherHistoryReportFilters,
    setVoucherHistoryReportSorting,
    getVoucherHistoryReportTotals,
    totals,
    isTotalsLoading,
}) => {
    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: VOUCHER_HISTORY_REPORT });

    const { t } = useTranslation();
    const { dateService } = useDate();
    const { formatAmount } = useFormat();

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.REPORTS_VOUCHER_HISTORY,
        action: PERMISSION_ACTION.EXPORT
    })

    const mappedTotals = useMemo(() => (
        totals.reduce((acc, item) => {
            acc[item.currencyCode] = { amount: item.amount };
            return acc;
        }, {})
    ), [totals])

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: getTableColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                t,
                dateService,
                isCashierReport: false,
                formatAmount,
            }
        });
    }, [includedColumns, t, dateService, formatAmount])

    const handleSearchChange = (searchValue) => {
        setVoucherHistoryReportFilters({
            ...filters,
            id: searchValue
        })
    }

    const headerPartsData = {
        filters: (
            <Filters />
        ),
        search: {
            placeholder: t("backoffice.reports.voucherId"),
            loadFn: getVoucherHistoryReport,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.BETSHOP,
                dependencies: [globalProjectId]
            },
            onSearch: handleSearchChange,
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns,
        },
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.voucherHistory') }],
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.voucherHistory"),
            url: ApiUrls.EXPORT_VOUCHERS,
            filters: { ...filters, ...sorting },
            columns: columnsForExport
        }
    }

    return (
        <MainDashboardLayout
            header={headerPartsData}
            isContentScrollable={isMobile()}
            extraContent={
                {
                    content: (
                        <TotalsComponent
                            getTotals={getVoucherHistoryReportTotals}
                            dependencies={[globalProjectId, filters]}
                            totals={mappedTotals}
                            isLoading={isTotalsLoading}
                            totalType={REPORT_TOTAL_TYPE.VOUCHER_HISTORY}
                        />
                    )
                }
            }
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={report}
                loadFn={getVoucherHistoryReport}
                updateProps={[globalProjectId]}
                sorting={sorting}
                total={total}
                enableReload={true}
                setSortingFn={setVoucherHistoryReportSorting}
                filters={filters}
                setFiltersFn={setVoucherHistoryReportFilters}
            />
        </MainDashboardLayout>
    );
};

const mapDispatchToProps = dispatch => ({
    getVoucherHistoryReport: nextPage => dispatch(getVoucherHistoryReport(nextPage)),
    setVoucherHistoryReportFilters: (filters, keepPage) => dispatch(setVoucherHistoryReportFilters(filters, keepPage)),
    setVoucherHistoryReportSorting: sorting => dispatch(setVoucherHistoryReportSorting(sorting)),
    getVoucherHistoryReportTotals: () => dispatch(getVoucherHistoryReportTotals())
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.voucherHistoryReport.isLoading,
        isTotalsLoading: state.voucherHistoryReport.isTotalsLoading,
        report: state.voucherHistoryReport.report,
        total: state.voucherHistoryReport.total,
        sorting: state.voucherHistoryReport.sorting,
        filters: state.voucherHistoryReport.filters,
        globalProjectId: state.common.globalProjectId,
        totals: state.voucherHistoryReport.totals,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherHistoryReport);
