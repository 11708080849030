import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {Input as AntInput} from "antd"

import { countDecimals } from 'utils/common';
import {isNull, isUndefined} from "utils/javaScriptTypes";

import Input from '../input';
import useFormat from 'hooks/useFormat';
import {LEFT_TO_RIGHT_MARK} from "constants/common.constants";


/** Numeric Input Component, allows only numeric characters in input */
const NumericInput = ({
	onChange,
	value,
	onBlur,
	placeholder,
	style,
	isInteger,
	disabled,
	emptyOnZero = false,
	max,
	decimalsCount,
	isMobileNumber,
	isOriginalInput,
	disableFormatting = false,
	...rest
}) => {
	const {formatNumber, normalizeNumber} = useFormat();

	const [hidePlaceholder, setHidePlaceholder] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	const prevIsFocused = useRef(false);

	const formattedValue = useMemo(() => {
		const res = (!isMobileNumber && value && !disableFormatting) ? formatNumber(value) : isNull(value) || isUndefined(value) ? "" : value;

		if (emptyOnZero && res.toString() === "0" && !isMobileNumber && isFocused && !prevIsFocused.current) {
			return "";
		}

		return res;
	}, [isMobileNumber, value, disableFormatting, formatNumber, emptyOnZero, isFocused]);

	const markedValue = !disableFormatting && formattedValue ? (LEFT_TO_RIGHT_MARK + formattedValue) : formattedValue;

	const normalizeValue = (value) => {
		return !isMobileNumber && !disableFormatting ? normalizeNumber(value) : value;
	}

	/** Fires on input change
	   * @function
	   * @description checks to allow only numeric characters
	   * @memberOf NumericInput
   */
	const onChangeHandler = e => {
		const markRemovedValue = e.target.value.replace(LEFT_TO_RIGHT_MARK, "");
		const value = normalizeValue(markRemovedValue);

		let reg = isInteger ? /^-?[0-9]*([0-9]*)?$/ : /^-?[0-9]*(\.[0-9]*)?$/;

		prevIsFocused.current = true;

		if(isMobileNumber){
			reg = /^\+?([0-9]*)?$/;
		}

		if (
			(
				!isNaN(value) && reg.test(value) && !value.startsWith("-") && (!decimalsCount || countDecimals(value) <= decimalsCount)
			) || (
				isMobileNumber && reg.test(value)
			) || value === ''
		) {
			if (!isNull(max) && !isUndefined(max) && Number(value) > max) {
				return;
			}

			onChange && onChange(value);
			if (e.target.value.length > 0) {
				setHidePlaceholder(true)
			} else {
				setHidePlaceholder(false)
			}
		}
	};

	/** Fires on input blur
	   * @function
	   * @description remove "." from value, if it's the last character
	   * @memberOf NumericInput
   */
	const onBlurHandler = () => {
		const v = value === undefined || value === null ? "" : value;
		let valueTemp = v + "";
		let changed = false;

		setIsFocused(false);

		if (!isInteger && valueTemp.charAt(valueTemp.length - 1) === '.') {
			valueTemp = valueTemp.slice(0, -1);
			changed = true;
		}
		if (valueTemp !== valueTemp.replace(/0*(\d+)/, '$1') && !isMobileNumber) {
			valueTemp = valueTemp.replace(/0*(\d+)/, '$1');
			changed = true;
		}

		if (changed && onChange) {
			onChange(valueTemp);
		}

		if (onBlur) {
			onBlur();
		}
	}

	const onFocusHandler = () => {
		prevIsFocused.current = isFocused;
		setIsFocused(true);
	}

	return isOriginalInput ? (
		<AntInput
			{...rest}
			style={style}
			onChange={onChangeHandler}
			onBlur={onBlurHandler}
			placeholder={placeholder}
			value={markedValue}
			disabled={disabled}
			autoComplete="off"
			className={hidePlaceholder ? "rt--hide-placeholder" : ""}
			onFocus={onFocusHandler}
		/>
	) : (
		<Input
			{...rest}
			style={style}
			onChange={onChangeHandler}
			onBlur={onBlurHandler}
			placeholder={placeholder}
			value={markedValue}
			disabled={disabled}
			autoComplete="off"
			className={hidePlaceholder ? "rt--hide-placeholder" : ""}
			onFocus={onFocusHandler}
		/>
	)
}

/** NumericInput propTypes
	* PropTypes
*/
NumericInput.propTypes = {
	/** Callback which called on input change */
	onChange: PropTypes.func,
	/** Input value*/
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/** Callback which called on input blur */
	onBlur: PropTypes.func,
	/** Input placeholder */
	placeholder: PropTypes.string,
	/** Input styles */
	style: PropTypes.object,
	/** Should input value be integer */
	isInteger: PropTypes.bool,
	/**Is input disabled */
	disabled: PropTypes.bool,
	/** Max value */
	max: PropTypes.number,
	/** Limitation for decimal numbers */
	decimalsCount: PropTypes.number,
	/** Allow Plus for mobile inputs */
	isMobileNumber: PropTypes.bool,
	/** Is original input */
	isOriginalInput: PropTypes.bool,
	/** Flag to disable formatting */
	disableFormatting: PropTypes.bool
}

export default NumericInput;
