export const REPORT_TOTAL_TYPE = {
    BET_HISTORY: 1,
    REAL_TIME_BETS: 2,
    PLAYER_BETS: 3,
    PLAYER_PERFORMANCE_REPORT: 4,
    AGENT_PERFORMANCE_REPORT: 5,
    BETSHOP_PERFORMANCE_REPORT: 6,
    AGENTS: 7,
    PRODUCT_REPORT: 8,
    NEW_PRODUCT_REPORT: 9,
    RETAIL_BET_HISTORY: 10,
    VOUCHER_HISTORY: 11
}

// Agent performance report
export const AGENT_REPORT_TYPES = {
    DIRECT: 1,
    NETWORK: 2,
}
