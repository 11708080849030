import {
    SET_VOUCHER_HISTORY_REPORT,
    SET_VOUCHER_HISTORY_REPORT_ACTION_BEFORE,
    SET_VOUCHER_HISTORY_REPORT_ACTION_FINISH,
    SET_VOUCHER_HISTORY_REPORT_FILTERS,
    SET_VOUCHER_HISTORY_REPORT_SORTING, SET_VOUCHER_HISTORY_REPORT_TOTALS,
    SET_VOUCHER_HISTORY_REPORT_TOTALS_BEFORE,
    SET_VOUCHER_HISTORY_REPORT_TOTALS_FINISH
} from "store/actionTypes";
import axios from "axios";
import ApiUrls from "constants/api.constants";
import Methods from "constants/methods.constants";
import {isMobile} from "utils/common";

const setVoucherHistoryReportActionBefore = () => ({
    type: SET_VOUCHER_HISTORY_REPORT_ACTION_BEFORE,
});

const setVoucherHistoryReportActionFinished = () => ({
    type: SET_VOUCHER_HISTORY_REPORT_ACTION_FINISH,
});

const setVoucherHistoryReport = (data, add) => ({
    type: SET_VOUCHER_HISTORY_REPORT,
    payload: { report: data.reports, total: data.total, add },
});

export const setVoucherHistoryReportFilters = (filters, keepPage) => {
    console.log({filters});
    return ({
        type: SET_VOUCHER_HISTORY_REPORT_FILTERS,
        payload: {filters, keepPage},
    });
};

export const setVoucherHistoryReportSorting = sorting => ({
    type: SET_VOUCHER_HISTORY_REPORT_SORTING,
    payload: { sorting },
});

export const getVoucherHistoryReport = nextPage => {
    return (dispatch, getState) => {
        const state = getState()
        const filters = { ...state.voucherHistoryReport.filters };
        const page = nextPage === "first" ? 1 : nextPage === "next" ? state.voucherHistoryReport.sorting.page + 1 : state.voucherHistoryReport.sorting.page;

        if (!filters.createdBy) {
            filters.creatorType = "";
        }

        let data = {
            ...state.voucherHistoryReport.sorting,
            ...filters,
            page: page
        };

        if (page > 1 && state.voucherHistoryReport.total <= (page - 1) * state.voucherHistoryReport.sorting.limit) {
            return Promise.resolve();
        }

        if (nextPage === "next" && state.voucherHistoryReport.total === 0) {
            return Promise.resolve();
        }

        dispatch(setVoucherHistoryReportActionBefore());

        return axios({
            url: `${ApiUrls.GET_VOUCHERS}`,
            method: Methods.GET,
            params: data,
        })
            .then(({ data }) => {
                const reports = data.value?.item2;
                const total = data.value?.item1;

                dispatch(setVoucherHistoryReport({ reports, total }, isMobile() && page !== 1));

                if (nextPage) {
                    dispatch(setVoucherHistoryReportSorting({ ...getState().voucherHistoryReport.sorting, page: page }));
                }

                dispatch(setVoucherHistoryReportActionFinished());
            })
            .catch((ex) => {
                dispatch(setVoucherHistoryReportActionFinished());
            });
    }
}

/** Totals */

const setVoucherHistoryReportTotalsBefore = () => ({
    type: SET_VOUCHER_HISTORY_REPORT_TOTALS_BEFORE,
});

const setVoucherHistoryReportTotalsFinished = () => ({
    type: SET_VOUCHER_HISTORY_REPORT_TOTALS_FINISH,
});

const setVoucherHistoryReportTotals = totals => ({
    type: SET_VOUCHER_HISTORY_REPORT_TOTALS,
    payload: { totals },
});

export const getVoucherHistoryReportTotals = () => {
    return (dispatch, getState) => {
        const state = getState();
        const filters = { ...state.voucherHistoryReport.filters };

        if (!filters.createdBy) {
            filters.creatorType = "";
        }

        let data = {
            ...filters
        };

        dispatch(setVoucherHistoryReportTotalsBefore());

        return axios({
            url: `${ApiUrls.GET_VOUCHERS_TOTALS}`,
            method: Methods.GET,
            params: data,
        })
            .then(({ data }) => {
                const totals = data.value;
                dispatch(setVoucherHistoryReportTotals(totals));
                dispatch(setVoucherHistoryReportTotalsFinished());
            })
            .catch((ex) => {
                dispatch(setVoucherHistoryReportTotalsFinished());
            });
    }
}
