import React, { useMemo } from "react";
import { connect } from 'react-redux';

import { getProjectLogosPaths } from "utils/common";

import { LOGO_TYPES } from 'constants/common.constants';

import LogoRetail from "assets/images/logo-retail-blue.svg";

const Header = ({
    companies,
    globalProjectId
}) => {
    
    const logoPaths = useMemo(() => {
        const paths = getProjectLogosPaths(companies, globalProjectId);

        return paths;
    }, [companies, globalProjectId])

    return (
        <div className='rt--customize-layout-header'>
            <div className='rt--customize-layout-header-logo'>
                <img alt="logo" src={logoPaths[LOGO_TYPES.LOGO] ? `${import.meta.env.SYSTEM_CDN_URL}/${logoPaths[LOGO_TYPES.LOGO]}` : LogoRetail} />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        globalProjectId: state.common.globalProjectId,
        companies: state.profile.userInfo.companies,
    }
}

export default connect(
    mapStateToProps,
    null
)(
    Header
);