import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Checkbox, Spin, Radio } from 'antd';

import SubTabFormDashboardLayout from 'components/layouts/tab/subtab/form';
import { saveProjectConfigs } from "store/actions/dashboard/projects/configs.action";
import { getUserInfo } from 'store/actions/dashboard/profile/userInfo.action';

import projectConfigsType from "types/project/configs.type";

import { binaryToFlags, flagsToBinary, isFranchisingMode } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';

import { BETSHOP_ACCESS_TYPE, BETSHOP_BET_TYPE } from 'constants/betshop.constants';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import {BETSHOP_LIMIT_INCREASE_TYPE} from "constants/project.constants";


/** Project Edit Page Configs Tab Component */
const ProjectConfigsComponent = ({
	saveProjectConfigs,
	isSaving,
	isLoading,
	configs,
	getUserInfo,
	onTabChange,
}) => {
	const { t } = useTranslation();

	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue, getFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);

	const [isNonAnonymousSelected, setIsNonAnonymousSelected] = useState(false)
    const [isAutoPayoutSelected, setIsAutoPayoutSelected] = useState(false)

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		const values = {
			betShopAccessType: binaryToFlags(Object.values(BETSHOP_ACCESS_TYPE), configs.betShopAccessType),
			betShopLimitIncreaseType: configs.betShopLimitIncreaseType
		}
		if (isFranchisingMode()) {
			values.betShopBettingType = configs.betShopBettingType;
			values.betShopAutoPayOut = configs.betShopAutoPayOut;
			values.sendBetViaSMS = configs.sendBetViaSMS;

			setIsNonAnonymousSelected(values.betShopBettingType === BETSHOP_BET_TYPE.NON_ANONYMOUS);
            setIsAutoPayoutSelected( values.betShopBettingType === BETSHOP_BET_TYPE.NON_ANONYMOUS && values.betShopAutoPayOut )
		}
		setFieldsValue(values)
	}, [configs])

	/** Fires when form submitted
		* @function
		* @memberOf ProjectConfigsComponent
  */
	const handleForm = () => {
		validateFields()
			.then(data => {
				const d = {
					betShopAccessType: flagsToBinary(data.betShopAccessType),
					betShopLimitIncreaseType: isAutoPayoutSelected ? configs.betShopLimitIncreaseType : data.betShopLimitIncreaseType
				}
				if (isFranchisingMode()) {
					d.betShopBettingType = data.betShopBettingType;
					d.betShopAutoPayOut = data.betShopAutoPayOut;
					d.sendBetViaSMS = data.sendBetViaSMS;
				}

				const onSuccess = () => {
					if (!isFranchisingMode()) {
						return;
					}

					if (configs.betShopBettingType !== d.betShopBettingType) {
						getUserInfo(true);
					}
				}

				saveProjectConfigs(d, onSuccess);
				setIsFormTouched(false);
			}).catch(err => {
				console.log(err)
			})
	}

	const handleBettingTypeChange = (e) => {
        const v = e.target.value;
		setIsNonAnonymousSelected(v === BETSHOP_BET_TYPE.NON_ANONYMOUS);
	}

	const handleFormValuesChange = (formValues) => {

		setIsFormTouched(
			isFormChanged(
				{
					betShopLimitIncreaseType: isAutoPayoutSelected ? configs.betShopLimitIncreaseType : formValues.betShopLimitIncreaseType,
					betShopAccessType: formValues.betShopAccessType,
					...(isFranchisingMode() ? {
						betShopBettingType: formValues.betShopBettingType,
						betShopAutoPayOut: (
							formValues.betShopBettingType === BETSHOP_BET_TYPE.NON_ANONYMOUS
								? formValues.betShopAutoPayOut
								: configs.betShopAutoPayOut
						),
						sendBetViaSMS: (
							formValues.betShopBettingType === BETSHOP_BET_TYPE.NON_ANONYMOUS
								? formValues.sendBetViaSMS
								: configs.sendBetViaSMS
						),
					} : {})

				},
				{
					betShopLimitIncreaseType: configs.betShopLimitIncreaseType,
					betShopAccessType: binaryToFlags(Object.values(BETSHOP_ACCESS_TYPE), configs.betShopAccessType),
					...(isFranchisingMode() ? {
						betShopBettingType: configs.betShopBettingType,
						betShopAutoPayOut: configs.betShopAutoPayOut,
						sendBetViaSMS: configs.sendBetViaSMS,
					} : {})
				}
			)
		)
	}

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched])

	useEffect(() => {
		const formValues = getFieldsValue();
		handleFormValuesChange(formValues);
	}, [isNonAnonymousSelected])


	/** Can edit data */
	const canEdit = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_REGULATIONS, action: PERMISSION_ACTION.MODIFY })

	return (
		<SubTabFormDashboardLayout
			buttons={
				[
					{
						type: "primary",
						onClick: handleForm,
						text: t("backoffice.common.save"),
						enabled: hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_REGULATIONS, action: PERMISSION_ACTION.MODIFY }),
						loading: isSaving,
						disabled: !isFormTouched
					}
				]
			}
		>
			<Spin spinning={isLoading} wrapperClassName="rt--form-spin">
				<Form
					colon={false}
					form={formInstance}
					requiredMark={false}
					layout="vertical"
					initialValues={{
						betShopBettingType: BETSHOP_BET_TYPE.ANONYMOUS,
						betShopAccessType: [],
						sendBetViaSMS: false,
						betShopAutoPayOut: false,
						limit: BETSHOP_LIMIT_INCREASE_TYPE.BY_WON
					}}
					onValuesChange={(changedFieldValues, formValues) => {
						const changedField = Object.keys(changedFieldValues)[0];
						if (changedField === "betShopBettingType") {
							return;
						}

						handleFormValuesChange(formValues)
					}}
				>
					<Row gutter={[16, 0]}>
						{
							isFranchisingMode() && (
								<Col span={24}>
									<span className='rt--form-section-title rt--title rt--flex rt--pb-16 rt--font-bold rt--font-biger'>
										{t("backoffice.projects.bets")}
									</span>
									<Form.Item
										name="betShopBettingType"
										rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
										className={"rt--form-item-radio" + (!canEdit ? " rt--form-item-disabled" : "")}
									>
										<Radio.Group
											options={[
												{ label: t('backoffice.projects.anonymous'), value: BETSHOP_BET_TYPE.ANONYMOUS },
												{ label: t('backoffice.projects.nonanonymous'), value: BETSHOP_BET_TYPE.NON_ANONYMOUS }
											]}
											disabled={!canEdit}
											onChange={handleBettingTypeChange}
										/>
									</Form.Item>
								</Col>
							)
						}
					</Row>
					<Row gutter={[16, 0]}>
						<Col xs={24} sm={12} md={8} xl={5}>
							<span className={'rt--form-section-title rt--title rt--flex rt--pb-16 rt--font-bold rt--font-biger' + (isFranchisingMode() ? " rt--mt-8" : "")}>
								{t("backoffice.projects.allowedActions")}
							</span>
							<Form.Item
								name="betShopAccessType"
								className={"rt--form-item-inline" + (!canEdit ? " rt--form-item-disabled" : "")}
								rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
							>
								<Checkbox.Group
									className='rt--checkbox-group-list'
									options={[
										{ label: t('backoffice.betshops.allowPlayerRegistration'), value: BETSHOP_ACCESS_TYPE.PLAYER_CREATE },
										{ label: t('backoffice.betshops.allowPayment'), value: BETSHOP_ACCESS_TYPE.PAYMENT },
										{ label: t('backoffice.betshops.allowBetPlacement'), value: BETSHOP_ACCESS_TYPE.BETS }
									]}
									disabled={!canEdit}
								/>
							</Form.Item>
						</Col>
						{(isFranchisingMode() && isNonAnonymousSelected) && (
							<Col xs={24} sm={12} md={8} xl={5}>
								<span className={'rt--form-section-title rt--title rt--flex rt--pb-16 rt--font-bold rt--font-biger' + (isFranchisingMode() ? " rt--mt-8" : "")}>
									{t("backoffice.projects.betShopActions")}
								</span>
								<Form.Item
									name="sendBetViaSMS"
									className={"rt--form-item-inline rt--form-item-without-margin" + (!canEdit ? " rt--form-item-disabled" : "")}
									valuePropName='checked'
								>
									<Checkbox disabled={!canEdit}>
										{t('backoffice.betshops.sendBetViaSMS')}
									</Checkbox>
								</Form.Item>
								<Form.Item
									name="betShopAutoPayOut"
									className={"rt--form-item-inline rt--mt-8" + (!canEdit ? " rt--form-item-disabled" : "")}
									valuePropName='checked'
								>
									<Checkbox
                                        disabled={!canEdit}
                                        onChange={e => {
                                            setIsAutoPayoutSelected(e.target.checked)
                                        }}
                                    >
										{t('backoffice.betshops.autoPayOut')}
									</Checkbox>
								</Form.Item>
							</Col>
						)}
					</Row>
                    {
                        (!isAutoPayoutSelected || !isNonAnonymousSelected) && (
                            <Row>
                                <Col xs={24} sm={12} md={8} xl={5}>
                                    <span className={'rt--form-section-title rt--title rt--flex rt--pb-16 rt--font-bold rt--font-biger'}>
                                        {t("backoffice.projects.limitIncreased")}
                                    </span>

                                    <Form.Item name="betShopLimitIncreaseType">
                                        <Radio.Group disabled={!canEdit}>
                                            <Radio value={BETSHOP_LIMIT_INCREASE_TYPE.BY_WON}>
                                                {t("backoffice.projects.wonTicket")}
                                            </Radio>
                                            <Radio value={BETSHOP_LIMIT_INCREASE_TYPE.BY_PAYOUT}>
                                                {t("backoffice.projects.payOutTicket")}
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        )
                    }

				</Form>
			</Spin>
		</SubTabFormDashboardLayout>
	)
}

/** ProjectConfigsComponent propTypes
	 * PropTypes
*/
ProjectConfigsComponent.propTypes = {
	/** Redux state property, is true when project configs is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when project configs is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the a project configs of current editing project  */
	configs: projectConfigsType,
	/** Redux action to save project configs */
	saveProjectConfigs: PropTypes.func,
	/** Redux action to get user info */
	getUserInfo: PropTypes.func,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
	{
		saveProjectConfigs: (data, onSuccess) => {
			dispatch(saveProjectConfigs(data, onSuccess));
		},

		getUserInfo: (loadInBackground) => {
			dispatch(getUserInfo(loadInBackground))
		}
	}
)

const mapStateToProps = state => {
	return {
		configs: state.projects.edit.configs.main,
		isSaving: state.projects.isSaving,
		isLoading: state.projects.isLoading
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectConfigsComponent)
